import { LOAD_NON_RATING_GAMES, LOAD_RATING_GAMES } from '../consts/actions';
import IGamesStore from '../models/store/games';
import IGamesAction from '../models/actions/games';

const defaultStore: IGamesStore ={
    rating: null,
    nonRating: null,
}

function gamesReducer(store = defaultStore, action: IGamesAction) {
    switch (action.type) {
        case LOAD_RATING_GAMES:
            return {
                ...store,
                rating: action.payload,
            }
        case LOAD_NON_RATING_GAMES:
            return {
                ...store,
                nonRating: action.payload,
            }
        default:
            return store;
    }
}

export default gamesReducer;
