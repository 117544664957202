export default function getQueryParams() {
    return window.location.hash
        .replace(/#\/[^?]*\?/, '')
        .split('&')
        .map(i => i.split('='))
        .reduce((acc, item) => {
            acc[item[0].toLowerCase()] = item[1];
            return acc;
        }, {} as { [key: string]: string });
}
