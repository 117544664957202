import React, { ChangeEvent, FormEvent, ReactElement } from 'react';
import styles from './styles.module.css';
import globalForm from '../forms.module.css';
import RegistrationTeam from '../../models/RegistrationTeam';
import countriesJson from '../../consts/countries.json';
import ICountry from '../../models/Country';
import Toast from "../Toast";

type TeamFormProps = {
    startValue: RegistrationTeam;
    loading: boolean;
    isEditForm?: boolean;
    onSubmit: (team: RegistrationTeam) => void;
};

type Validations = { [key: string]: string };

type TeamFormState = {
    teamInfo: RegistrationTeam;
    validations: Validations;
};

const countries = countriesJson as ICountry[]

export default class TeamForm extends React.Component<TeamFormProps, TeamFormState> {
    constructor(props: TeamFormProps) {
        super(props);

        this.state = {
            teamInfo: { ...props.startValue },
            validations: {},
        }
    }

    render() {
        const { isEditForm, loading } = this.props;

        let buttonText: string;

        if (isEditForm) {
            buttonText = loading ? "Updating..." : "Update";
        } else {
            buttonText = loading ? "Registering..." : "Register";
        }

        return (
            <form className={ globalForm.form } onSubmit={ this.registerTeam }>
                { this.renderRow('Team name *', 'teamName', true, {
                    autoFocus: !isEditForm,
                    maxLength: 100,
                }) }
                { this.renderRow('Contact email *', 'customData_email', true, {
                    type: 'email',
                    maxLength: 100,
                }) }
                { this.renderRow('Repository SSH URL *', 'gitRepositoryUrl', true, {
                    maxLength: 1024,
                    placeholder: 'git@example.com:user/repo.git'
                }) }
                { this.renderCountrySelect() }
                { this.renderRow('Team size', 'customData_peopleAmount', false, {
                    type: 'number',
                    maxLength: 100,
                }) }
                <div className={ styles.buttonRow }>
                    <span/>
                    <div>
                        <button type='submit' disabled={ loading }>
                            { buttonText }
                        </button>
                    </div>
                </div>
            </form>
        )
    }

    renderRow(label: string | ReactElement, fieldName: keyof RegistrationTeam, required = false, props?: Object) {
        const { validations } = this.state
        const isError = fieldName in validations;

        return (
            <label className={ styles.row }>
                <span>{ label }</span>
                <span style={ { width: '100%' } }>
                    <input className={ isError ? ' ' + styles.error : '' }
                           name={ fieldName }
                           value={ this.state.teamInfo[fieldName] }
                           required={ required }
                           onChange={ this.onChange(fieldName) }
                           { ...props }
                    />
                    { isError && <Toast message={ validations[fieldName] } isError /> }
                </span>
            </label>
        );
    }

    renderCountrySelect() {
        return (
            <label className={ styles.row }>
                <span>Your country</span>
                <select name='customData_country'
                        value={ this.state.teamInfo['customData_country'] }
                        onChange={ this.onChange('customData_country') }>
                    { countries.map(country =>
                        <option value={ country.value } key={ country.value }>{ country.text }</option>
                    ) }
                </select>
            </label>
        )
    }

    onChange = (field: keyof RegistrationTeam) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const val = e.target.value;
        this.setState({
            teamInfo: {
                ...this.state.teamInfo,
                [field]: val,
            }
        })
    }

    registerTeam = async (e: FormEvent) => {
        e.preventDefault();

        if (!this.validate()) return;

        this.props.onSubmit(this.state.teamInfo);
    }

    validate() {
        const { teamName, gitRepositoryUrl } = this.state.teamInfo;

        const validations: Validations = {};
        if (!teamName) {
            validations['teamName'] = 'Team Name is required for registration';
        }

        if (!gitRepositoryUrl) {
            validations['gitRepositoryUrl'] = 'Repository SSH URL is required for registration';
        }

        if (!/[^@]+@[^@:]+/.test(gitRepositoryUrl)) {
            validations['gitRepositoryUrl'] = 'Make sure the url you enter is a valid SSH URL';
        }

        this.setState({ validations })

        return Object.keys(validations).length === 0;
    }
}
