import React from 'react';
import styles from './styles.module.css';
import emptyTeam from '../../consts/emptyTeam';
import TeamForm from '../TeamForm';
import RegistrationTeam from '../../models/RegistrationTeam';


type RegistrationPageProps = {
    loading: boolean;
    register: (team: RegistrationTeam) => void;
}

export default function RegistrationPage(props: RegistrationPageProps) {
    return (
        <div className={ styles.root }>
            <h2>Team Registration</h2>
            <div className={ styles.disclaimer }>
                <p>As part of registration, we kindly ask to create a private Git repository for your team and provide
                    its SSH URL.</p>
                <p>Don't worry, all information you enter here can be updated later.</p>
            </div>
            <TeamForm startValue={ emptyTeam } onSubmit={ props.register } loading={ props.loading }/>
        </div>
    )
}
