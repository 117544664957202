export const ROUTES = {
    rules: '/rules',
    registration: '/registration',
    login: '/login',
    teamInfo: '/team',
    submissions: '/submissions',
    scoreboard: '/scoreboard',
    testing: '/testing',
    games: '/games',
    visualize: '/visualize',
    blog: '/blog',
    post: '/post',
};

export const blogTabs = {
    orgs: '#orgs',
    pegovka: '#pegoovka',
}

export const gameTabs = {
    rating: '#rating',
    nonRating: '#nonRating',
}

export const scoreboardTabs = {
    lightning: '#lightning',
    full: '#full',
    final: '#final',
}
