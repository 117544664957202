import { FINISH, LOAD_TEAM, START, UPDATE_API_KEY, UPDATE_TEAM } from '../consts/actions';
import ITeamAction from '../models/actions/team';
import ITeamStore from '../models/store/team';

const defaultState: ITeamStore = {
    apiKey: null,
    team: null,
    teamLoading: false,
}


function teamReducer(state = defaultState, action: ITeamAction) {
    switch (action.type) {
        case UPDATE_API_KEY:
            return {
                ...state,
                apiKey: action.payload,
            };
        case UPDATE_TEAM:
            return {
                ...state,
                team: action.payload,
            };
        case LOAD_TEAM + START:
            return {
                ...state,
                teamLoading: true,
            }
        case LOAD_TEAM + FINISH:
            return {
                ...state,
                teamLoading: false,
            }
        default:
            return state;
    }
}

export default teamReducer;
