import React from 'react';
import styles from './styles.module.css';

const interval = 5000

type ToastProps = {
    message: string | JSX.Element;
    isError?: boolean;
    onClose?: () => void;
}

export default class Toast extends React.Component<ToastProps> {
    interval?: NodeJS.Timeout

    componentDidMount() {
        const { onClose } = this.props;

        if (onClose !== undefined) {
            this.interval = setTimeout(onClose, interval)
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearTimeout(this.interval);
        }
    }

    render() {
        const { message, isError } = this.props;

        return (
            <div className={ styles.root }>
                <div className={ styles.box + (isError ? ' ' + styles.error : '') }>
                    { message }
                </div>
            </div>
        )
    }
}
