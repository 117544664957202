import React from 'react';
import styles from './style.module.css';

type DateTimeProps = {
    dateStr: string;
}

export const formatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    month: 'short',
    day: '2-digit',
    hour12: false
})

export default function DateTime(props: DateTimeProps) {
    const { dateStr } = props;
    const date = new Date(dateStr);

    return (
        <span className={ styles.root } title={Intl.DateTimeFormat().resolvedOptions().timeZone}>
            {formatter.format(date)}
        </span>
    )
}
