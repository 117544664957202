import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';

type BlogPostProps = {
    id: number,
    title: string,
    date: string,
    author: string,
    text: string,
    isInList: boolean
}

export default function BlogPost(props: BlogPostProps) {
    const { title, date, author, text, isInList, id } = props;
    const intl = new Intl.DateTimeFormat('en-GB', {
        timeZoneName: 'short',
        hour: 'numeric',
        minute: '2-digit',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })


    return (
        <article className={ styles.root + ' container' }>
            <header className={ styles.header }>
                { renderTitle(isInList, title, id) }
                <div className={ styles.subtitle }>
                    <time>{ intl.format(new Date(date)) }</time>
                    , by { author }
                </div>
            </header>
            <ReactMarkdown linkTarget='_blank' escapeHtml={false} source={ text }/>
        </article>
    )
}

function renderTitle(isInList: boolean, titleText: string, id: number) {
    if (!isInList) {
        return <h2 className={ styles.title }>{ titleText }</h2>
    }

    return <h3 className={ styles.title }><Link to={ `${ ROUTES.post }/${ id }` }>{ titleText }</Link></h3>
}
