import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import styles from './styles.module.css';
import Header from '../Header';
import Team from '../../models/Team';
import { API_KEY } from '../../consts/strings';
import Error from '../Error'
import AppRouter from './AppRouter';
import listenEvents, { EventType, IListener } from '../../utils/eventsListener';
import { ROUTES } from '../../consts/routes';
import VisualizerPage from '../VisualizerPage';
import getQueryParams from '../../utils/getQueryParams';


interface AppProps {
    apiKey: string | null;
    team: Team | null;
    error: Error | string | null;
    updateApiKey: (apiKey: string | null) => void;
    updateTeam: (team: Team | null) => void;
    loadTeam: () => void;
    setError: (err: Error | string | null) => void;
    loadSubmissions: () => void;
    loadLightningScoreboard: () => void;
    loadScoreboard: () => void;
    loadNonRatingGames: () => void;
}

interface AppState {
    hasApiKey: boolean;
    apiKey?: string | null;
}

class App extends React.Component<AppProps, AppState> {
    private listener?: IListener;

    constructor(props: AppProps) {
        super(props);

        let apiKey = getApiKey();
        this.state = {
            hasApiKey: Boolean(apiKey),
            apiKey: apiKey,
        }
    }

    componentDidMount() {
        let apiKey = getApiKey();

        if (!apiKey) return;

        this.props.updateApiKey(apiKey);
        this.props.loadTeam();
    }

    componentWillUnmount() {
        this.listener && this.listener.close();
    }

    componentDidUpdate(prevProps: Readonly<AppProps>) {
        const addTeam = !prevProps.team && Boolean(this.props.team);
        const delTeam = Boolean(prevProps.team) && !this.props.team;

        if (delTeam) {
            this.listener && this.listener.close();
        }
        if (addTeam) {
            this.addListeners();
        }
    }

    render() {
        const { hasApiKey, apiKey } = this.state;
        const { team, error } = this.props;

        const loading = hasApiKey && !team;
        return (
            <>
                <Router>
                    <Switch>
                        <Route path={ ROUTES.visualize } children={ () =>
                            <VisualizerPage apiKey={ apiKey }/>
                        }/>
                        <Route>
                            <div className={ styles.container }>
                                <Header team={ team }
                                        isTeamLoading={ loading }
                                        logout={ this.deleteApiKey }/>
                                <div className={ styles.narrowContainer }>
                                    <AppRouter team={ team }
                                               isTeamLoading={ loading }
                                    />
                                </div>
                            </div>
                        </Route>
                    </Switch>
                </Router>

                { error !== null && <Error error={ error } closeError={ this.closeError }/> }
            </>
        );
    }


    deleteApiKey = () => {
        try {
            const storageApiKey = window.localStorage.getItem(API_KEY);
            if (storageApiKey === this.props.apiKey) {
                window.localStorage.removeItem(API_KEY);
            }
        } catch (e) {
            /*...*/
        }

        this.setState({ hasApiKey: false });
        this.props.updateApiKey(null);
        this.props.updateTeam(null);
    }

    closeError = () => {
        this.props.setError(null);
    }

    addListeners() {
        if (!this.props.apiKey) return;

        this.listener = listenEvents(this.props.apiKey, (eventType, payload) => {
            if (!payload) return;
            switch (eventType) {
                case EventType.Submission:
                    return this.props.loadSubmissions();
                case EventType.Team:
                    return this.props.loadTeam();
                case EventType.LightningScoreboard:
                    return this.props.loadLightningScoreboard();
                case EventType.Scoreboard:
                    return this.props.loadScoreboard();
                case EventType.NonRatingGame:
                    return this.props.loadNonRatingGames();
            }
        })

        this.listener.subscribe(EventType.Submission);
        this.listener.subscribe(EventType.Team);
        this.listener.subscribe(EventType.LightningScoreboard);
        this.listener.subscribe(EventType.Scoreboard);
        this.listener.subscribe(EventType.NonRatingGame);
    }
}

export default App;

function getApiKey() {
    let apiKey;
    try {
        const urlParams = getQueryParams();
        
        if (urlParams['apikey']) {
            apiKey = urlParams['apikey'];
            window.localStorage.setItem(API_KEY, apiKey);
        } else {
            apiKey = window.localStorage.getItem(API_KEY);
        }
    } catch (e) {
        /*...*/
    }
    
    return apiKey;
}
