import { LOAD_CURRENT_TOURNAMENT } from '../consts/actions';
import ITournamentStore from '../models/store/tournaments';
import ITournamentAction from '../models/actions/tournaments';

const defaultStore: ITournamentStore ={
    current: null
}

function tournamentReducer(store = defaultStore, action: ITournamentAction) {
    switch (action.type) {
        case LOAD_CURRENT_TOURNAMENT:
            return {
                ...store,
                current: action.payload,
            }
        default:
            return store;
    }
}

export default tournamentReducer;
