import React from 'react';
import { blogTabs } from '../../consts/routes';
import { RouteComponentProps } from 'react-router-dom';
import Tabs from '../Tabs';
import BlogList from './BlogList';
import astronomerPosts from '../../astronomerPosts.json';
import orgsPosts from '../../orgsPosts.json';

interface BlogPageProps {
    location: RouteComponentProps['location'];
}

const tabs = [
    { text: 'Contest Updates', hash: blogTabs.orgs },
    { text: 'Pegovka', hash: blogTabs.pegovka },
]

export default function BlogPage(props: BlogPageProps) {
    const { location } = props;
    const hash = location.hash;
    return (
        <div>
            <Tabs tabs={ tabs } location={ location }/>
            <BlogList posts={hash === blogTabs.pegovka ? astronomerPosts : orgsPosts} />
        </div>
    )
}
