import { LOAD_CURRENT_TOURNAMENT } from '../consts/actions';
import { ThunkAction } from 'redux-thunk';
import IStore from '../models/store';
import IAppAction from '../models/actions/app';
import { Dispatch } from 'redux';
import TournamentDto from '../models/TournamentDto';
import { createSetErrorAction } from './app';
import { sendGetRequest } from '../utils/sendRequest';
import { currentTournament } from '../consts/api';

export const createLoadCurrentTournamentAction = (tournament: TournamentDto) => ({
    type: LOAD_CURRENT_TOURNAMENT,
    payload: tournament,
})

export function loadCurrentTournament():
    ThunkAction<Promise<any>, IStore, unknown, IAppAction>  {
    return async (dispatch: Dispatch) => {
        try {
            const result = await sendGetRequest(currentTournament);
            dispatch(createLoadCurrentTournamentAction(result));
            return result;
        } catch (e) {
            dispatch(createSetErrorAction(e));
        }
    }
}
