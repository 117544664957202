import IRenderer from "./IRenderer";
import {IShipAndCommands} from "./GameLogModels";
import Renderer2d from "./Renderer2d";
import Renderer3d from "./Renderer3d";

export default class Renderer2dAnd3d implements IRenderer {
    renderer2d: IRenderer;
    renderer3d: IRenderer;

    constructor(renderer2d: Renderer2d, renderer3d: Renderer3d) {
        this.renderer2d = renderer2d;
        this.renderer3d = renderer3d;
    }

    setTimePerTick(timePerTick: number): void {
        this.renderer2d.setTimePerTick(timePerTick);
        this.renderer3d.setTimePerTick(timePerTick);
    }

    adjustScale(ships: IShipAndCommands[]): void {
        this.renderer2d.adjustScale(ships);
        this.renderer3d.adjustScale(ships);
    }

    clearSpace(): void {
        this.renderer2d.clearSpace();
        this.renderer3d.clearSpace();
    }

    drawShipsAndCommands(prevShipsAndCommands: IShipAndCommands[] | undefined,
                         shipsAndCommands: IShipAndCommands[],
                         nextShipsAndCommands: IShipAndCommands[] | undefined,
                         isAutoPlay: boolean, getShootRadius: (power: number, powerDecrease: number) => number): void {
        this.renderer2d.drawShipsAndCommands(prevShipsAndCommands, shipsAndCommands, nextShipsAndCommands, isAutoPlay, getShootRadius);
        this.renderer3d.drawShipsAndCommands(prevShipsAndCommands, shipsAndCommands, nextShipsAndCommands, isAutoPlay, getShootRadius);
    }

    drawPlanet(radius: number): void {
        this.renderer2d.drawPlanet(radius);
        this.renderer3d.drawPlanet(radius);
    }

    drawSafePlace(radius: number): void {
        this.renderer2d.drawSafePlace(radius);
        this.renderer3d.drawSafePlace(radius);
    }

    drawTrajectories(ships: IShipAndCommands[], showTrajectories: boolean): void {
        this.renderer2d.drawTrajectories(ships, showTrajectories);
        this.renderer3d.drawTrajectories(ships, showTrajectories);
    }
}