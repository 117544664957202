import React from 'react';
import './styles.css';
import run from './Visualizer';
import getQueryParams from '../../utils/getQueryParams';
import {games} from '../../consts/api';
import createQueryString from '../../utils/createQueryString';
import GameDto, {GameWinner} from '../../models/GameDto';

interface VisualizerPageProps {
    apiKey?: string | null;
}

export default class VisualizerPage extends React.Component<VisualizerPageProps> {
    componentDidMount() {
        this.runGame();
    }

    runGame() {
        const queries = getQueryParams()
        const gameId = queries['game'];
        const playerKey = queries['playerkey'];
        const autoGames = queries['autogames'];

        if (autoGames) {
            this.runAutoGames();
            return;
        }
        
        if (gameId || playerKey) {
            this.getLog(gameId, playerKey);
        }
    }

    render() {
        const queries = getQueryParams()
        const gameId = queries['game'];
        const gray = { color: 'gray' }
        const autoGames = queries['autogames'];
        return (
            <div id='visualizerWrapper'>
                {!autoGames && <ul className={'instruction'}  >
                    <li><span style={ gray }>Controls:</span> A D Q E <span style={ gray }>/</span> ← → Home End</li>
                    <li><span style={ gray }>Autoplay:</span> Space , . -</li>
                    <li><span style={ gray }>Zoom:</span> + -</li>
                    <li><span style={ gray }>2D/3D:</span> 1</li>
                    <li className={ 'only2dcontrol' }><span style={ gray }>Trajectories:</span> click</li>
                    <li className={ 'only3dcontrol' }><span style={ gray }>3D camera:</span> W S Z</li>
                    <li className={ 'only3dcontrol' }><span style={ gray }>Fullscreen:</span> Enter</li>
                </ul>}
                {gameId && <h3 id='title'>&nbsp;</h3>}
                <canvas id='field2d' width={800} height={500}/>
                <canvas id='field3d' width={800} height={500}/>
                <div id='console'/>
            </div>
        )
    }

    runAutoGames = async () => {
        const {apiKey} = this.props;
        if (!apiKey) return;

        const gameIds: string[] = await fetch(`${games}/interesting-games/?${createQueryString({apiKey})}`).then(res => res.json());
        for (let gameId of gameIds) {            
            let promise = new Promise((resolve) => {
                this.getLog(gameId, undefined, () => { resolve() })
            });
            await promise;            
        }        
        this.runAutoGames();
    }
    
    getLog = async (gameId?: string, playerKey?: string, done?: (() => void)) => {
        if (gameId) {
            try {
                const requestUrl = `${games}/log?${createQueryString({gameId})}`;
                const response = await fetch(requestUrl).then(res => res.text());
                const log = response.replace(/\(/g, '[').replace(/\)/g, ']');

                const gameRequestUrl = `${games}/${gameId}?${createQueryString({})}`;
                const gameResponse = await fetch(gameRequestUrl).then(res => res.json());

                run(JSON.parse(log), done ? 50 : 0, done);
                this.renderTitle(gameResponse);

            } catch (e) {
                if (done)
                    done();                
            }

            return
        }

        if (playerKey) {
            try {
                const requestUrl = `${games}/log?${createQueryString({playerKey})}`;
                const response = await fetch(requestUrl).then(res => res.text());
                const log = response.replace(/\(/g, '[').replace(/\)/g, ']');

                run(JSON.parse(log), done ? 200 : 40, done);

            } catch (e) {
                if (done)
                    done();
            }

            return
        }
    }

    renderTitle(game: GameDto) {
        const title = document.getElementById('title');
        if (!title) return;

        const attackerName = game.attacker.team.teamName;
        const defenderName = game.defender.team.teamName;

        const winner = (game.winner && game.winner !== GameWinner.Nobody) ? game.winner : null;

        const attacker = `<span class="attacker ${winner === GameWinner.Defender ? 'streak' : ''}">${attackerName}</span>`;
        const defender = `<span class="defender ${winner === GameWinner.Attacker ? 'streak' : ''}">${defenderName}</span>`;
        title.innerHTML = `${defender} vs ${attacker}`;
    }
}
