import React from 'react';
import styles from './styles.module.css';
import { RouteComponentProps } from 'react-router-dom';
import { gameTabs, ROUTES } from '../../consts/routes';
import Tabs from '../Tabs';
import GameDto, { GameWinner } from '../../models/GameDto';
import Team from '../../models/Team';
import { logs } from '../../consts/api';
import createQueryString from '../../utils/createQueryString';
import DateTime from '../DateTime';

interface GamesPageProps {
    team: Team | null;
    location: RouteComponentProps['location'];
    ratingGames?: GameDto[] | null;
    nonRatingGames?: GameDto[] | null;
    loadRatingGames: () => void;
    loadNonRatingGames: () => void;
}

const tabs = [
    { text: 'Rating', hash: gameTabs.rating },
    { text: 'Non-Rating', hash: gameTabs.nonRating },
];

enum roles {
    attacker = 'attacker',
    defender = 'defender',
}

enum result {
    inProgress = 'In progress',
    win = 'Win',
    lose = 'Lose',
    draw = 'Draw',
}

export default class GamesPage extends React.Component<GamesPageProps> {
    componentDidMount() {
        if (!this.props.ratingGames) {
            this.props.loadRatingGames();
        }
        if (!this.props.nonRatingGames) {
            this.props.loadNonRatingGames();
        }
    }

    render() {
        const hash = this.props.location.hash;
        return (
            <div>
                <Tabs tabs={ tabs } location={ this.props.location }/>
                { (!hash || hash === gameTabs.rating) && this.renderRatingGames() }
                { hash === gameTabs.nonRating && this.renderNonRatingGames() }
            </div>
        )
    }

    renderRatingGames() {
        const { ratingGames } = this.props;
        return (
            <table className={ styles.table }>
                { this.renderTableHeader() }
                <tbody>
                { ratingGames && ratingGames.map(i => this.renderRow(i)) }
                </tbody>
            </table>
        )
    }


    renderNonRatingGames() {
        const { nonRatingGames } = this.props;
        return (
            <table className={ styles.table }>
                { this.renderTableHeader() }
                <tbody>
                { nonRatingGames && nonRatingGames.map(i => this.renderRow(i, true)) }
                </tbody>
            </table>
        )
    }

    renderTableHeader() {
        return (
            <thead>
            <tr>
                <th>Result</th>
                <th>Attacker</th>
                <th>Defender</th>
                <th>Logs</th>
                <th>Viz</th>
                <th>Stats</th>
            </tr>
            </thead>
        )
    }

    renderRow = (item: GameDto, nonRating = false) => {
        const { team } = this.props;
        const gameStatus = this.calcGameResult(item);

        return (
            <tr key={ item.gameId }>
                <td className={ gameStatus ? styles[gameStatus] : undefined } style={ { whiteSpace: "nowrap" } }>{ gameStatus }</td>
                <td>{ item.attacker.team.teamName }
                    { nonRating && team?.teamId === item.attacker.team.teamId
                        ? ' #' + item.attacker.submissionId :
                        ''
                    }</td>
                <td>{ item.defender.team.teamName }
                    { nonRating && team?.teamId === item.defender.team.teamId
                        ? ' #' + item.defender.submissionId
                        : '' }</td>
                <td>{ gameStatus === result.inProgress ? null : this.renderLogLinks(item) }</td>
                <td>{ gameStatus !== result.inProgress && item.ticks !== undefined && item.ticks >= 0 &&
                <a href={ `/#${ROUTES.visualize}?game=${ item.gameId }` }
                   rel="noopener noreferrer"
                   target='_blank'>Watch</a> }</td>
                <td>{ this.renderStats(item) }</td>
            </tr>
        )
    }

    renderLogLinks(game: GameDto) {
        const { team } = this.props;
        return (
            <>
                { game.attacker.debugLog &&
                <div>
                    <a href={ `${ logs }?${ createQueryString({
                        logKey: game.attacker.debugLog,
                        apiKey: team?.apiKey
                    }) }` }
                       rel="noopener noreferrer" target="_blank">
                        attacker debug log</a>
                </div>
                }
                { game.defender.debugLog &&
                <div>
                    <a href={ `${ logs }?${ createQueryString({
                        logKey: game.defender.debugLog,
                        apiKey: team?.apiKey
                    }) }` }
                       rel="noopener noreferrer" target="_blank">
                        defender debug log</a>
                </div>
                }
            </>

        )
    }

    renderStats(game: GameDto) {
        return (
            <>
                { game.createdAt && <div style={ { whiteSpace: "nowrap" } }>Start: <DateTime dateStr={ game.createdAt }/></div> }
                { game.finishedAt && <div style={ { whiteSpace: "nowrap" } }>Finish: <DateTime dateStr={ game.finishedAt }/></div> }
                { !!game.ticks && <div style={ { whiteSpace: "nowrap" } }>Duration: { game.ticks || 0 } { game.ticks !== 1 ? 'ticks' : 'tick' }</div> }
                { game.defender.timeout && <div style={ { whiteSpace: "nowrap" } }>Defender timeout</div> }
                { game.attacker.timeout && <div style={ { whiteSpace: "nowrap" } }>Attacker timeout</div> }
            </>
        )
    }

    calcGameResult(game: GameDto) {
        const { team } = this.props;
        if (!team) {
            return null;
        }

        if (!game.winner) {
            return result.inProgress;
        }

        if (game.winner === GameWinner.Nobody) {
            return result.draw;
        }

        let role = null;

        if (game.attacker.team.teamId === team.teamId) {
            role = roles.attacker;
        }
        if (game.defender.team.teamId === team.teamId) {
            role = roles.defender;
        }

        if (!role || game.attacker.team.teamId === game.defender.team.teamId) {
            return game.winner + ' wins';
        }

        if (game.winner.toLowerCase() === role) {
            return result.win;
        }

        return result.lose;
    }
}
