import React from 'react';

interface PlayIconProps {
    color?: string;
}

export default function PlayIcon(props: PlayIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width="25px" height="20px" viewBox="20 60 110 90" enableBackground="new 0 0 150 190"
             xmlSpace="preserve">
            <path fillRule="evenodd" clipRule="evenodd" fill={ props.color || "#00bfff" } d="M95.693,86.301c-4.998-4.416-10.621-5.84-14.584-3.823
    c-2.53,1.289-4.058,3.9-4.397,7.361l1.697,0.188l2.094,8.631c12.766,0.131,25.108,0.266,33.579,0.337
    c6.308,0.053,10.649,1.572,10.649,10.935c0,9.363,0.229,22.767,0.198,28.352c-0.034,6.369-4.154,8.633-9.891,8.726
    c-3.172,0.051-65.049-0.578-81.744-0.048c-5.542,0.176-8.242-3.824-8.355-10.988c-0.114-7.164,0.158-18.532,0.158-26.877
    c0-8.346,3.366-10.778,10.192-10.778c7.658,0,19.349,0.092,31.781,0.209l2.19-9.509l1.489,0.165
    c0.569-5.47,3.272-9.826,7.636-12.047c6.188-3.15,14.329-1.372,21.244,4.641c6.601,5.737,16.484,4.271,21.189-2.913l4.268,3.754
    C119.143,92.188,104.568,94.142,95.693,86.301z M112.932,137.325c4.271-4.271-2.671-10.989-7.141-6.52
    C101.407,135.189,107.864,142.391,112.932,137.325z M98.204,137.325c4.271-4.271-2.671-10.989-7.141-6.52
    C86.679,135.189,93.137,142.391,98.204,137.325z M94.136,124.476c4.077-0.017,12.968-0.183,16.218-0.112
    c11.747,0.255,10.067-16.551,1.118-16.775c-8.747-0.219-14.666-0.085-18.694-0.015C82.342,107.754,82.887,124.521,94.136,124.476z
    M59.696,121.976l-8.083,0.284v-6.991l-8.083,0.172l-0.161,7.109l-8.094,0.285l0.344,7.223l7.576,0.108l-0.181,7.974h8.599v-7.854
    l8.083,0.116V121.976z M98.204,119.188c-5.067,5.066-11.525-2.135-7.141-6.52C95.533,108.2,102.476,114.917,98.204,119.188z
    M112.932,119.188c-5.067,5.066-11.524-2.135-7.141-6.52C110.261,108.2,117.203,114.917,112.932,119.188z"/>
        </svg>
    )
}
