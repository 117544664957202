import React, { ReactComponentElement } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LandingPage from '../Landing/LandingPage';
import { ROUTES } from '../../consts/routes';
import RulesPage from '../RulesPage';
import RegistrationPage from '../../containers/Registration';
import LoginPage from '../../containers/Login';
import Team from '../../models/Team';
import TeamInfoPage from '../../containers/TeamInfo';
import SubmissionsPage from '../../containers/Submissions';
import ScoreboardPage from '../../containers/Scoreboard';
import BlogPage from '../BlogPage';
import BlogPostPage from '../BlogPostPage';
import TestingPage from '../../containers/Testing';
import GamesPage from '../../containers/Games';

type AppRouterProps = {
    team: Team | null;
    isTeamLoading: boolean;
}

export default function AppRouter(props: AppRouterProps) {
    const { team, isTeamLoading } = props;
    return (
        <Switch>
            <Route exact path='/' component={ LandingPage }/>
            <Route path={ ROUTES.rules } component={ RulesPage }/>
            <Route path={ ROUTES.registration } children={ () => {
                const component = <RegistrationPage/>;
                return forUnauthorized(team, component)
            } }/>
            <Route path={ ROUTES.login } children={ () => {
                const component = <LoginPage/>;
                return forUnauthorized(team, component)
            } }/>
            <Route path={ ROUTES.teamInfo } children={ () => {
                const component = <TeamInfoPage/>;
                return forAuthorized(team, isTeamLoading, component);
            } }/>
            <Route path={ ROUTES.submissions } children={ () => {
                const component = <SubmissionsPage/>;
                return forAuthorized(team, isTeamLoading, component);
            } }/>
            <Route path={ ROUTES.scoreboard } component={ ScoreboardPage }/>
            <Route path={ ROUTES.blog } component={ BlogPage }/>
            <Route path={ `${ROUTES.post}/:postId` } component={ BlogPostPage }/>
            <Route path={ ROUTES.testing } children={ () => {
                const component = <TestingPage/>;
                return forAuthorized(team, isTeamLoading, component);
            } }/>

            <Route path={ ROUTES.games } children={ ({ location }) => {
                const component = <GamesPage location={ location }/>;
                return forAuthorized(team, isTeamLoading, component);
            } }/>

            <Route path={ ROUTES.scoreboard } children={ ({ location }) =>
                <ScoreboardPage location={ location }/>
            }/>
        </Switch>
    )
}

function forUnauthorized(team: Team | null, component: ReactComponentElement<any>) {
    if (team) {
        return <Redirect to={ ROUTES.teamInfo }/>
    }
    return component;
}


function forAuthorized(team: Team | null, loading: boolean, component: ReactComponentElement<any>) {
    if (!team && !loading) {
        return <Redirect to={ ROUTES.login }/>
    }

    if (!team) {
        return null;
    }

    return component;
}
