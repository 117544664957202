import React from 'react';
import styles from './styles.module.css';
import globalForm from '../forms.module.css';
import CopyToClipboard from 'react-copy-to-clipboard';
import Toast from '../Toast';

interface ShowAndCopyFormProps {
    name: string;
    value: string;
    rows?: number;
}

interface ShowAndCopyFormState {
    showToast: boolean;
}

export default class ShowAndCopyForm extends React.Component<ShowAndCopyFormProps, ShowAndCopyFormState> {
    state = {
        showToast: false,
    }

    render() {
        const { showToast } = this.state;
        const rows = this.props.rows || 1

        return (
            <form className={ `${ styles.root } ${ globalForm.form }` }>
                { rows === 1 && (
                    <input type="text" disabled value={ this.props.value }/>
                ) }

                { rows !== 1 && (
                    <textarea disabled rows={ rows } value={ this.props.value }/>
                ) }

                <div>
                    <CopyToClipboard text={ this.props.value }>
                        <button type='button' onClick={ this.showToast }>Copy { this.props.name }</button>
                    </CopyToClipboard>
                </div>
                { showToast && <Toast message='Copied to clipboard' onClose={ this.closeToast }/> }
            </form>
        )
    }

    showToast = () => {
        this.setState({ showToast: true });
    }

    closeToast = () => {
        this.setState({ showToast: false });
    }
}
