import { connect } from 'react-redux';
import App from '../components/App';
import { loadTeam, updateApiKey, updateTeam } from '../actions/team';
import createEmptyAction from '../utils/createEmptyAction';
import { ThunkDispatch } from 'redux-thunk';
import IStore from '../models/store';
import Team from '../models/Team';
import { setErrorAction } from '../actions/app';
import { AnyAction } from 'redux';
import { loadSubmissions } from '../actions/submissions';
import { loadLightningScoreboard, loadTotalScoreboard } from '../actions/scoreboard';
import { loadNonRatingGames } from '../actions/games';


const mapState = (state: IStore) => {
    return {
        apiKey: state.team.apiKey,
        team: state.team.team,
        error: state.app.error,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        updateApiKey: (apiKey: string | null) => dispatch(updateApiKey(createEmptyAction(apiKey))),
        updateTeam: (team: Team | null) => dispatch(updateTeam(createEmptyAction(team))),
        loadTeam: () => dispatch(loadTeam()),
        setError: (err: string | Error | null) => dispatch(setErrorAction(createEmptyAction(err))),
        loadSubmissions: () => dispatch(loadSubmissions()),
        loadLightningScoreboard: () => dispatch(loadLightningScoreboard()),
        loadScoreboard: () => dispatch(loadTotalScoreboard()),
        loadNonRatingGames: () => dispatch(loadNonRatingGames())
    }
}

export default connect(mapState, mapDispatch)(App);
