import React from 'react';

export default function RulesPage() {
    return (
        <main>
            <h2>Contest Rules</h2>
            <h3>1. Participants</h3>
            <p>
                This is an open contest. Anyone may participate in ICFP Programming Contest 2020 except for its Organizers.
                Employees of the company which Organizers are affiliated with are not eligible for prizes.
            </p>
            <h3>2. Teams</h3>
            <p>
                Contestants are free to organize themselves into teams of any size.
                A contestant may only be a member of a single team.
                Teams may not divide, merge, or collaborate after the start of the contest.
            </p>
            <h3>3. Registration</h3>
            <p>
                No advance registration or entry fee is required.
                Teams must register during the contest to obtain a team-specific private identifier that will be used for submissions and profiles.
                Teams using more than one private identifier during the contest may be disqualified.
            </p>
            <h3>4. Languages & platforms</h3>
            <p>
                Teams are free to use any programming language(s) on any platform(s).
                Some programming languages and platforms may require additional tuning of the submission system.
                Participants are encouraged to check the <a href="https://github.com/icfpcontest2020/dockerfiles/blob/master/README.md" target="_blank" rel="noopener noreferrer">supported languages and platforms</a> in advance and inform Organizers if their language or platform of choice is missing.
            </p>
            <h3>5. Prizes</h3>
            <p>
                Teams wishing to be considered for prizes must submit source code at the end of the contest.
                The exact details of contest submissions will be announced at the start of the contest.
                Teams may submit multiple times during the contest.
            </p>
            <p>
                The Organizers will distribute US $2,000 in prize money between the teams that will take the 1st, the 2nd, and the 3rd places in the contest (full  72-hour division).
            </p>
            <h3>6. Records</h3>
            <p>
                The Organizers retain the right to monitor, record, and investigate the submissions, other contest-related activities, or lack thereof, of contestants and teams.
                The records are used for the sole purpose of judgment and are discarded once the contest-related events are over.
            </p>
            <h3>7. Fair play</h3>
            <p>
                Contestants are kindly asked not to attempt to attack the contest server.
                That will spoil the fun for the other teams and the Organizers, who worked hard to bring you this experience.
                Violations of these rules, attempts to compromise the integrity of the contest infrastructure, attempts to interfere with other contestants, collusion between the teams, or attempts to contravene the spirit of the contest will lead to disqualification of the involved contestants and/or teams.
            </p>
            <h3>8. Intellectual property</h3>
            <p>
                Contestants retain ownership of all intellectual property rights in and to any submitted solutions, source code, custom tools, and related materials (“Submissions”) that contestants had before submission.
                As a condition of submission, contestants grant the Organizers a non-exclusive, perpetual, irrevocable, worldwide, royalty-free license to use, reproduce, publish, distribute, publicly perform and publicly display the Submissions, allowing the Organizers to test and evaluate the Submissions for purposes of the contest.
            </p>
            <h3>9. Authority</h3>
            <p>All decisions of the Organizers are final.</p>

            <h2>Changelog</h2>
            <h3>Jul 6, 2020 — 1. Participants</h3>
            <p>
                Organizers deem unfeasible to prevent ~9,000 employees of Kontur, the company which also employs the majority of Organizers, from participation in the contest.
                However, Organizers declare that they kept secret from their coworkers everything which may give them any competitive advantage.
                Therefore, Organizers have removed the limitation for employees of Kontur to participate in the contest.
                At the same time, Organizes have added the rule that employees of Kontur are not eligible for prizes.
            </p>
            <h3>Jul 6, 2020 — 4. Languages & platforms</h3>
            <p>
                Organizers have decided to use a particular kind of <a href="https://github.com/icfpcontest2020/dockerfiles/blob/master/README.md" target="_blank" rel="noopener noreferrer">submission system</a> for this contest which requires teams to use Git and Docker.
                Therefore, Organizers have added the suggestion for teams to check the supported languages and platforms in advance.
            </p>
            <h3>Jul 14, 2020 — 5. Prizes</h3>
            <p>
                Organizers have clarified the amount of prize money awarded to the winning teams.
            </p>
        </main>
    )
}
