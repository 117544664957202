import { LOAD_FINAL_SCOREBOARD, LOAD_PROBLEM_SCOREBOARD, LOAD_TOTAL_SCOREBOARD } from '../consts/actions';
import IScoreboardAction from '../models/actions/scoreboard';
import { ThunkAction } from 'redux-thunk';
import IStore from '../models/store';
import { Dispatch } from 'redux';
import { createSetErrorAction } from './app';
import { sendGetRequest } from '../utils/sendRequest';
import { finalScoreboard, problemsScoreboard, scoreboard } from '../consts/api';
import {
    ILightningScoreboard,
    ITotalScoreboard
} from '../models/Scoreboard';

const createLoadLightningScoreboardAction = (scoreboard: ILightningScoreboard) => ({
    type: LOAD_PROBLEM_SCOREBOARD,
    payload: scoreboard,
})

const createLoadTotalScoreboardAction = (scoreboard: ITotalScoreboard) => ({
    type: LOAD_TOTAL_SCOREBOARD,
    payload: scoreboard,
})

const createLoadFinalScoreboardAction = (scoreboard: ITotalScoreboard) => ({
    type: LOAD_FINAL_SCOREBOARD,
    payload: scoreboard,
})


export function loadLightningScoreboard():
    ThunkAction<Promise<any>, IStore, unknown, IScoreboardAction> {
    return async (dispatch: Dispatch) => {
        try {
            const result: ILightningScoreboard = await sendGetRequest(problemsScoreboard)
            
            dispatch(createLoadLightningScoreboardAction(result))
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}

export function loadTotalScoreboard():
    ThunkAction<Promise<any>, IStore, unknown, IScoreboardAction> {
    return async (dispatch: Dispatch) => {
        try {
            const result: ITotalScoreboard = await sendGetRequest(scoreboard)

            dispatch(createLoadTotalScoreboardAction(result))
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}

export function loadFinalScoreboard():
    ThunkAction<Promise<any>, IStore, unknown, IScoreboardAction> {
    return async (dispatch: Dispatch) => {
        try {
            const result: ITotalScoreboard = await sendGetRequest(finalScoreboard)

            dispatch(createLoadFinalScoreboardAction(result))
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}

