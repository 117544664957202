import { connect } from 'react-redux';
import LoginPage from '../components/LoginPage';
import IStore from '../models/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { login } from '../actions/team';
import createEmptyAction from '../utils/createEmptyAction';

const mapState = (state: IStore) => {
    return {
        loading: state.team.teamLoading,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        login: (apiKey: string) => dispatch(login(createEmptyAction(apiKey))), 
    }
}


export default connect(mapState, mapDispatch)(LoginPage);
