import {
    ApiCommandType,
    ApiPlayerRole,
    IDetonateCommand,
    ILaserCommand,
    IShip,
    IShipAndCommands,
    V
} from "./GameLogModels";
import IRenderer from "./IRenderer";
import {simulate} from "./Visualizer";

export default class Renderer2d implements IRenderer
{
    ctx: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;
    cellSize: number;

    constructor(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
        this.ctx = ctx;
        this.canvas = canvas;
        this.cellSize = 10;
    }

    clearSpace() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    drawTrajectories(ships: IShipAndCommands[], showTrajectories: boolean) {
        if (!showTrajectories)
            return;
        this.ctx.globalAlpha = 0.5;
        for (let s of ships) {
            const points = simulate(s.ship, 15);
            const path = new Path2D();
            path.moveTo(this.toScreenX(s.ship.position.x + 0.5), this.toScreenY(s.ship.position.y + 0.5));
            for (let p of points) {
                let sx = this.toScreenX(p.x + 0.5);
                let sy = this.toScreenY(p.y + 0.5);
                path.lineTo(sx, sy);
            }
            this.ctx.strokeStyle = this.shipColor(s.ship);
            this.ctx.stroke(path);
        }
        this.ctx.globalAlpha = 1;
    }

    drawLaser(ship: IShip, laserCommand: ILaserCommand, getShootRadius: (power: number, powerDecrease: number) => number) {
        const target = laserCommand.target;
        const damage = laserCommand.damage;
        const damageDecreaseFactor = laserCommand.damageDecreaseFactor;
        const laser = new Path2D();
        laser.moveTo(this.toScreenX(ship.position.x + 0.5), this.toScreenY(ship.position.y + 0.5));
        laser.lineTo(this.toScreenX(target.x + 0.5), this.toScreenY(target.y + 0.5));
        this.ctx.fillStyle = "white";
        this.ctx.fill(this.createRect(target.x, target.y, getShootRadius(damage, damageDecreaseFactor)));
        this.ctx.strokeStyle = "yellow";
        this.ctx.stroke(laser);
    }

    drawDetonation(ship: IShip, detonateCommand: IDetonateCommand) {
        const power = detonateCommand.power;
        const powerDecrease = detonateCommand.powerDecreaseStep;
        const r = power / powerDecrease - 1;
        this.ctx.fillStyle = "magenta";
        this.ctx.fill(this.createRect(ship.position.x, ship.position.y, r));
    }

    drawShipsAndCommands(revShipsAndCommands: IShipAndCommands[] | undefined,
                         shipsAndCommands: IShipAndCommands[],
                         nextShipsAndCommands: IShipAndCommands[] | undefined,
                         isAutoPlay: boolean, getShootRadius: (power: number, powerDecrease: number) => number)
    {
        this.drawCommands(shipsAndCommands, getShootRadius);
        for (const shipAndCommands of shipsAndCommands)
            this.drawShip(shipAndCommands.ship);
    }

    drawCommands(ships: IShipAndCommands[], getShootRadius: (power: number, powerDecrease: number) => number) {
        for (let ship of ships)
            for (let cmd of ship.appliedCommands) {
                if (cmd.type === ApiCommandType.Detonate) this.drawDetonation(ship.ship, cmd);
                else if (cmd.type === ApiCommandType.Shoot) this.drawLaser(ship.ship, cmd, getShootRadius);
            }
    }

    drawPlanet(radius: number) {
        this.ctx.fillStyle = "white";
        this.ctx.fill(this.createRect(0, 0, radius));
    }

    drawSafePlace(radius: number) {
        this.ctx.fillStyle = "rgb(0,0,0)";
        this.ctx.fill(this.createRect(0, 0, radius));
    }

    drawShip(ship: IShip) {
        const pos = ship.position;
        this.ctx.fillStyle = this.shipColor(ship);
        this.ctx.fill(this.createRect(pos.x, pos.y));
    }

    createRect(gameX: number, gameY: number, radius = 0) {
        const res = new Path2D();
        let size = (2 * radius + 1) * this.cellSize;
        let left = this.toScreenX(gameX - radius);
        let top = this.toScreenY(gameY - radius);
        const minSize = 4;
        if (size < minSize) {
            left -= (minSize - size) / 2;
            top -= (minSize - size) / 2;
            size = minSize;
        }
        res.rect(left, top, size, size);
        return res;
    }

    toScreenX(gameX: number) {
        const originX = this.canvas.width / 2 - this.cellSize / 2;
        return originX + gameX * this.cellSize;
    }

    toScreenY(gameY: number) {
        const originY = this.canvas.height / 2 - this.cellSize / 2;
        return originY + gameY * this.cellSize;
    }

    fitInScreen(pos: V) {
        const screenX = this.toScreenX(pos.x);
        const screenY = this.toScreenY(pos.y);
        return screenX > 0 && screenX < this.canvas.width && screenY > 0 && screenY < this.canvas.height;
    }

    adjustScale(ships: IShipAndCommands[]) {
        while (this.cellSize > 0.4 && ships.some(s => !this.fitInScreen(s.ship.position)))
            this.cellSize /= 1.2;
    }

    shipColor(ship: IShip) {
        return ship.role === ApiPlayerRole.Defender ? 'teal' : 'orange';
    }

    setTimePerTick(timePerTick: number): void {
    }
}
