export default interface Submission {
    submissionId: string;
    createdAt: string;
    commitHash?: string;
    branchName?: string;
    commitMessage?: string;
    buildLogKey?: string;
    attackerTestLogKey?: string;
    defenderTestLogKey?: string;
    status: SubmissionStatus;
    active: boolean;
}

export enum SubmissionStatus
{
    Building = 'Building',
    Testing = 'Testing',
    Failed_Build = 'Failed_Build',
    Failed_Test = 'Failed_Test',
    Failed_Unknown = 'Failed_Unknown',
    Succeeded = 'Succeeded',
}
