import React from 'react';
import Toast from "../Toast";

type ErrorProps = {
    error: Error | string;
    closeError: () => void;
}

export default function Error(props: ErrorProps) {
    const { closeError, error } = props;
    const errorText = typeof error === 'string' ? error : error.message;
    return (
        <Toast message={ errorText } isError onClose={ closeError } />
    )
}
