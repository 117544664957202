import ISubmissionsStore from '../models/store/submissions';
import ISubmissionsAction from '../models/actions/submissions';
import {
    ACTIVATE_SUBMISSION, FINISH,
    LOAD_OTHER_TEAMS_SUBMISSION,
    LOAD_SUBMISSIONS, RUN_NON_RATING_GAME, START, SUCCESS,
    UPDATE_LAST_SEEN_SUBMISSION
} from '../consts/actions';

const defaultStore: ISubmissionsStore ={
    submissions: null,
    lastSeen: null,
    otherTeamSubmissions: null,
    running: false,
    justStarted: false,
}

function submissionsReducer(store = defaultStore, action: ISubmissionsAction) {
    switch (action.type) {
        case LOAD_SUBMISSIONS:
            return {
                ...store,
                submissions: action.payload,
            };
        case UPDATE_LAST_SEEN_SUBMISSION:
            return {
                ...store,
                lastSeen: action.payload,
            }
        case ACTIVATE_SUBMISSION:
            return {
                ...store,
                submissions: store.submissions?.map(i => {
                    if (i.submissionId === action.payload) {
                        return {...i, active: true};
                    }
                    if (i.active) {
                        return {...i, active: false};
                    }
                    return  i;
                })
            }
        case LOAD_OTHER_TEAMS_SUBMISSION:
            return {
                ...store,
                otherTeamSubmissions: action.payload,
            }
        case RUN_NON_RATING_GAME + START:
            return {
                ...store,
                running: true,
            }
        case RUN_NON_RATING_GAME + SUCCESS:
            return {
                ...store,
                running: false,
                justStarted: true,
            }
        case RUN_NON_RATING_GAME + FINISH:
            return {
                ...store,
                running: false,
                justStarted: false,
            }
        default:
            return store;
    }
}

export default submissionsReducer;
