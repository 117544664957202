import React from 'react';
import styles from './styles.module.css';
import ScoreboardPage from './ScoreboardPage';
import Team from '../../models/Team';
import { ITotalScoreboard, ITotalScoreboardTeam } from '../../models/Scoreboard';
import { GENERAL } from '../../consts/strings';
import DateTime from '../DateTime';
import TournamentDto from '../../models/TournamentDto';

interface FullScoreboardProps {
    team: Team | null;
    scoreboard: ITotalScoreboard;
}

interface FullScoreboardState {
    sortBy: string | number;
    openTournament: number | null;
    currentTournament?: TournamentDto | null;
}

export default class TotalScoreboard extends React.Component<FullScoreboardProps, FullScoreboardState> {
    constructor(props: FullScoreboardProps) {
        super(props);

        const currentTournament = TotalScoreboard.getCurrentTournament(props.scoreboard.tournaments);

        this.state = {
            sortBy: GENERAL,
            openTournament: currentTournament?.tournamentId || null,
            currentTournament,
        }
    }

    static getCurrentTournament(tournaments: TournamentDto[]) {
        return tournaments.find(i => i.acceptSubmissions && i.runGames);
    }

    render() {
        const { scoreboard, team } = this.props;
        const { sortBy } = this.state;

        const isFrozen = !!scoreboard.frozenAt && new Date(scoreboard.frozenAt) <= new Date();

        const sortedArray = [...scoreboard.teams].sort(sortElements(sortBy));
        return (
            <>
                <h2>Scoreboard{ team && ': ' + team.teamName } { isFrozen &&
                <span className={ styles.frozenTitle }> (frozen at <DateTime dateStr={ scoreboard.frozenAt as string }/>)</span> }</h2>
                { this.renderTopText() }
                <table className={ styles.table }>
                    { this.renderHead(sortedArray) }
                    <tbody>
                    { sortedArray.map(this.renderRow) }
                    </tbody>
                </table>
            </>
        )
    }

    renderTopText() {
        const { currentTournament } = this.state;

        return (
            <>
                <p className={ styles.help }>Ratings are calculated
                    using <a href='https://en.wikipedia.org/wiki/TrueSkill'
                             target='_blank' rel="noopener noreferrer">TrueSkill ranking system</a>.
                    Read more about stages and scoring in <a
                        href='https://message-from-space.readthedocs.io/en/latest/game.html#scoring'
                        target='_blank' rel='noopener noreferrer'>our documentation</a>.
                </p>
                { currentTournament && <p className={ styles.help }>
                    Current stage #{ currentTournament?.tournamentId } ends <DateTime
                    dateStr={ currentTournament?.finishedAt || '' }/></p> }
            </>
        )
    }

    renderHead(teamsOrder: ITotalScoreboardTeam[]) {
        const { scoreboard, team } = this.props;
        const { sortBy, openTournament } = this.state;

        const { tournaments } = scoreboard;
        const pastAndCurrentTournaments = tournaments.filter(isPastOrCurrentTournament);
        const buttonClass = `${ styles.sortButton } ${ sortBy === GENERAL ? styles.sortDown : '' }`

        const openTournamentFull = tournaments.find(i => i.tournamentId === openTournament);
        return (
            <thead>
            <tr>
                <th style={ { width: 40 } } rowSpan={ 3 }>#</th>
                <th rowSpan={ 3 } className={ styles.alignLeft }>Team Name</th>
                <th rowSpan={ 3 } className={ styles.alignLeft }>Platform</th>
                <th colSpan={ pastAndCurrentTournaments.length + (openTournamentFull ? 3 : 0) }>Stages</th>
                <th style={ { width: 70 } } className={ styles.score } rowSpan={ 3 }>
                    <button onClick={ this.changeSorted(GENERAL) }
                            className={ buttonClass }>
                        Total Score
                    </button>
                </th>
            </tr>

            <tr>
                { pastAndCurrentTournaments.map(this.renderTournamentHeadCell) }
            </tr>
            <tr>
                { openTournamentFull && this.renderOpenTournamentHeadCell(openTournamentFull) }
            </tr>
            { teamsOrder && teamsOrder
                .map((team, i) => ({ team, i }))
                .filter(tuple => tuple.team.team.teamId === team?.teamId && tuple.i > 5)
                .map(tuple => this.renderRow(tuple.team, tuple.i))
            }
            </thead>
        )
    }

    renderTournamentHeadCell = (t: TournamentDto) => {
        const { sortBy, openTournament } = this.state;
        const buttonClass = `${ styles.sortButton } ${ sortBy === t.tournamentId ? styles.sortDown : '' }`
        const isOpen = openTournament === t.tournamentId;

        const button = (
            <button onClick={ this.changeSorted(t.tournamentId) }
                    className={ buttonClass }>
                { t.tournamentId }
            </button>
        )

        const openClass = `${ styles.smallRow }`
        return (
            <td key={ t.tournamentId }
                rowSpan={ isOpen ? 1 : 2 }
                colSpan={ isOpen ? 4 : 1 }
                className={ isOpen ? openClass : styles.alignRight }
                title={ t.startedAt ? `Started at ${ ScoreboardPage.createDate(t.startedAt) }` : undefined }>
                { isOpen ? t.tournamentId : button }
            </td>
        )

    }
    renderOpenTournamentHeadCell = (t: TournamentDto) => {
        const { sortBy } = this.state;
        const buttonClass = `${ styles.sortButton } ${ sortBy === t.tournamentId ? styles.sortDown : '' }`
        const openClass = `${ styles.alignRight } ${ styles.details }`
        const buttonOpenClass = `${ styles.alignRight } ${ styles.details } ${ styles.sortDetails }`;

        return [
            <td key={ 'rating' } className={ openClass }>Rating</td>,
            <td key={ 'mu' } className={ openClass }>μ</td>,
            <td key={ 'sigma' } className={ openClass }>σ</td>,

            <td key={ t.tournamentId }
                className={ buttonOpenClass }
                title={ t.startedAt ? `Started at ${ ScoreboardPage.createDate(t.startedAt) }` : undefined }>
                <button onClick={ this.changeSorted(t.tournamentId) }
                        className={ buttonClass }>
                    { 'Score' }
                </button>
            </td>,
        ]
    }

    renderRow = (scoreboardTeam: ITotalScoreboardTeam, index: number) => {
        const { scoreboard } = this.props;
        const currentTeam = this.props.team;
        const teamInfo = scoreboardTeam.team

        const currentTournamentId = 8;
        return (
            <tr key={ scoreboardTeam.team.teamId }
                className={ currentTeam?.teamId === teamInfo.teamId ? styles.activeTeam : '' }>
                <td className={ styles.alignRight }>{ index + 1 }</td>
                { ScoreboardPage.renderTeamName(teamInfo, index + 1) }
                <td>{ currentTournamentId && scoreboardTeam.tournaments[currentTournamentId]?.submission.platform }</td>
                { scoreboard.tournaments
                    .filter(isPastOrCurrentTournament)
                    .map(t => this.renderTournamentCell(t, scoreboardTeam)) }
                <td className={ styles.score }>{ scoreboardTeam.score }</td>
            </tr>
        )
    }

    renderTournamentCell = (t: TournamentDto, scoreboardTeam: ITotalScoreboardTeam) => {
        const { openTournament } = this.state;
        const isOpen = openTournament === t.tournamentId;

        const hasItem = t.tournamentId in scoreboardTeam.tournaments;

        const openClass = `${ styles.alignRight } ${ styles.details }`;
        const buttonOpenClass = `${ styles.alignRight } ${ styles.details } ${ styles.sortDetails }`;

        return [
            isOpen && (<td key={ 'rating' } className={ openClass }>
                { hasItem && scoreboardTeam.tournaments[t.tournamentId].rating.conservativeRating.toFixed(2) }
            </td>),
            isOpen && (<td key={ 'mu' } className={ openClass }>
                { hasItem && scoreboardTeam.tournaments[t.tournamentId].rating.mu.toFixed(2) }
            </td>),
            isOpen && (<td key={ 'sigma' } className={ openClass }>
                { hasItem && scoreboardTeam.tournaments[t.tournamentId].rating.sigma.toFixed(2) }
            </td>),

            <td key={ t.tournamentId } className={ isOpen ? buttonOpenClass : styles.alignRight }>{ hasItem
                ? scoreboardTeam.tournaments[t.tournamentId].score
                : <span className={ styles.lighten }>—</span>
            }</td>
        ];
    }

    changeSorted = (sortBy: string | number) => () => {
        if (sortBy === this.state.sortBy) {
            return;
        }
        this.setState({ sortBy, openTournament: typeof sortBy === 'string' ? this.state.openTournament : sortBy })
    }
}

function isPastOrCurrentTournament(t: TournamentDto) {
    if (t.acceptSubmissions && t.runGames) {
        return true;
    }
    if (!t.acceptSubmissions) {
        return true;
    }

    return false;
}


function sortElements(sortBy: string | number) {
    return (a: ITotalScoreboardTeam, b: ITotalScoreboardTeam) => {
        const aProblem = sortBy === GENERAL ? a.score : a.tournaments[sortBy];
        const bProblem = sortBy === GENERAL ? b.score : b.tournaments[sortBy];

        if (!aProblem && !bProblem) return 0;
        if (!aProblem && bProblem) return 1;
        if (aProblem && !bProblem) return -1;

        let aScore = typeof aProblem === 'number' ? aProblem : aProblem.rating.conservativeRating || 0;
        let bScore = typeof bProblem === 'number' ? bProblem : bProblem.rating.conservativeRating || 0;

        if (aScore > bScore) return -1;
        if (aScore < bScore) return 1;
        return 0;
    }
}
