import {ApiPlayerRole, IShip} from "./GameLogModels";
import {MathUtils, Vector2, Vector3} from "three";

export enum ShipComponentType {
    Engine = 0,
    Radiator = 1,
    Laser = 2,
    Fuel = 3,
}

export interface IShipComponent {
    position: Vector3;
    type: ShipComponentType;
}

export function GetShipComponentsCount(ship: IShip): number {
    const matter = ship.matter;
    return matter.engines + matter.radiators + matter.lasers + matter.fuel;
}

// max 512 components in ship
export function GetShipComponents(ship: IShip): IShipComponent[] {
    const role = ship.role;
    const matter = ship.matter;
    const components : IShipComponent[] = [];
    AddComponentsWithType(components, matter.engines, ShipComponentType.Engine);
    AddComponentsWithType(components, matter.radiators, ShipComponentType.Radiator);
    AddComponentsWithType(components, matter.lasers, ShipComponentType.Laser);
    AddComponentsWithType(components, matter.fuel, ShipComponentType.Fuel);
    if (role === ApiPlayerRole.Defender) {
        for (let i=0; i<components.length; i++)
            SetPositionDefender(components[i], i + 1);
    } else
        SetPositionsAttacker(components);
    return components;
}

function AddComponentsWithType(components: IShipComponent[], count: number, matter: ShipComponentType) {
    for (let i=0; i<count; i++)
        components.push({ position: new Vector3(), type: matter });
}

function SetPositionsAttacker(cubes: IShipComponent[]) {
    const usedPoints = new Set();
    let indexForFigureWithDuplicates = 2;
    for (let i=1; i<cubes.length; i++) {
        let pos: Vector3;
        let key: string;
        while(true) {
            pos = GetPositionAttackerForFigureWithDuplicateFacesEdges(indexForFigureWithDuplicates);
            key = `${pos.x} ${pos.y} ${pos.z}`;
            indexForFigureWithDuplicates++;
            if (!usedPoints.has(key))
                break;
        }
        cubes[i].position = pos;
        usedPoints.add(key);
    }
}

function GetPositionAttackerForFigureWithDuplicateFacesEdges(index: number): Vector3 {
   return GetPosition(index, GetAttackerCubesCountOnLayerWithDuplicateFacesEdges, GetCoordsOnSideAttacker, false);
}

function GetAttackerCubesCountOnLayerWithDuplicateFacesEdges(layer: number): number {
    if (layer === 1)
        return 1;
    return 6 * layer * layer;
}

function GetAttackerCubesCountOnLayer(layer: number): number {
    if (layer === 1)
        return 1;
    return (layer - 1) * 4 * layer + (layer - 2) * (layer - 2) * 2;
    // Периметр грани умножили на длину грани. Осталось закрасить центр по двум сторонам.
}

function SetPositionDefender(cube: IShipComponent, index: number) {
    cube.position = GetPosition(index, GetDefenderCubesCountOnLayer, GetCoordsOnSideDefender, true);
}

function GetPosition(index: number,
                     getCubesCountOnLayer: (layer: number) => number,
                     getCoordsOnSide : (isCenterCubeOnLayer: boolean, indexOnSide: number) => Vector2,
                     isCenterCubeOnEvenLayer : boolean)
        : Vector3
{
    const {layer, indexOnLayer} = GetLayerAndIndexOnLayer(index, getCubesCountOnLayer);
    if (layer === 1)
        return new Vector3();
    const face = (indexOnLayer - 1) % 6 + 1; // 6 граней: передняя, левая, правая, верхняя, нижняя, задняя
    const indexOnFace = Math.ceil(indexOnLayer / 6);
    const isCenterCubeOnLayer = layer % 2 === (isCenterCubeOnEvenLayer ? 0 : 1);
    let coordsOnFace: Vector2;
    if (isCenterCubeOnLayer && indexOnFace === 1) {
        coordsOnFace = new Vector2(0, 0);
    }
    else {
        // сторона грани: лево-верх, право-низ, верх-право, низ-лево. Угловой куб берется по направлению первого слова
        const side = (indexOnFace - 1 - (isCenterCubeOnLayer ? 1 : 0)) % 4 + 1;
        const indexOnSide = Math.ceil((indexOnFace - (isCenterCubeOnLayer ? 1 : 0)) / 4);
        const coordsOnSide = getCoordsOnSide(isCenterCubeOnLayer, indexOnSide);
        coordsOnFace =
            side === 1 ? coordsOnSide :
                side === 3 ? new Vector2(coordsOnSide.y, -coordsOnSide.x) : // rotate 90%
                    side === 2 ? new Vector2(-coordsOnSide.x, -coordsOnSide.y) :
                        new Vector2(-coordsOnSide.y, coordsOnSide.x);
    }
    return CoordOnFaceTo3d(coordsOnFace, layer, face);
}

const AttackerSideColumnsAndRows_CenterOnLayer = [[1, 1], [1, 2], [2, 1], [2, 2], [1, 3],
    [2, 3], [3, 1], [3, 2], [1, 4], [3, 3], [2, 4], [3, 4]];
const AttackerSideColumnsAndRows_CenterNotOnLayer = [[1, 1], [2, 1], [1, 2], [2, 2], [3, 1], [3, 2], [2, 3], [3, 3]];
function GetCoordsOnSideAttacker(isCenterCubeOnLayer: boolean, indexOnSide: number) : Vector2 {
    // Колонки вертикальны, столбцы горизонтальны. Координаты считаются от центра фигуры.
    if (isCenterCubeOnLayer) {
        const [column, row] = AttackerSideColumnsAndRows_CenterOnLayer[indexOnSide - 1];
        return new Vector2(-2 * column, 2 * (row - 1));
    }
    const [column, row] = AttackerSideColumnsAndRows_CenterNotOnLayer[indexOnSide - 1];
    return new Vector2(-2 * column + 1, 2 * row - 1);
}

function GetCoordsOnSideDefender(isCenterCubeOnLayer: boolean, indexOnSide: number) : Vector2  {
    // индексы на стороне идут от углового по часовой стрелке. Сначала колонка ближе к центру.
    const column =
        isCenterCubeOnLayer
            ? (indexOnSide > 2 ? 2 : 1)
            : (indexOnSide > 4 ? 3 : (indexOnSide > 1 ? 2 : 1));
    const indexOnColumn =
        isCenterCubeOnLayer
            ? (column === 1 ? indexOnSide : indexOnSide - 2)
            : (column === 1 ? indexOnSide : (column === 2 ? indexOnSide - 1 : indexOnSide - 4));
    const coordsOnSide = new Vector2(
        -column * 2 + (isCenterCubeOnLayer ? 0 : 1) + (indexOnColumn - 1),
        indexOnColumn - 1);
    return coordsOnSide;
}

function CoordOnFaceTo3d(coordsOnFace: Vector2, layer: number, face: number) : Vector3 {
    let result = new Vector3(coordsOnFace.x, layer - 1, coordsOnFace.y);
    if (face === 2) {
        result = result.applyAxisAngle(new Vector3(0, 0, 1), MathUtils.degToRad(180)); // counterclockwise
        result = new Vector3(result.x, result.y, -result.z);
    }
    if (face === 3) {
        result = result.applyAxisAngle(new Vector3(0, 0, 1), MathUtils.degToRad(90));
        result = result.applyAxisAngle(new Vector3(1, 0, 0), MathUtils.degToRad(-90));
        result = new Vector3(-result.x, result.y, result.z);
        result = new Vector3(-result.x, result.y, -result.z);
    }
    if (face === 4) {
        result = result.applyAxisAngle(new Vector3(0, 0, 1), MathUtils.degToRad(-90));
        result = result.applyAxisAngle(new Vector3(1, 0, 0), MathUtils.degToRad(90));
    }
    else if (face === 5) {
        result = result.applyAxisAngle(new Vector3(1, 0, 0), MathUtils.degToRad(90));
    }
    else if (face === 6) {
        result = result.applyAxisAngle(new Vector3(1, 0, 0), MathUtils.degToRad(-90));
        result = new Vector3(-result.x, result.y, result.z);
    }
    result = new Vector3(Math.round(result.x), Math.round(result.y), Math.round(result.z))
    return result;
}

function GetDefenderCubesCountOnLayer(layer: number): number {
    if (layer === 1)
        return 1;
    return 6 * (layer - 1) * (layer - 1); // 6 граней
}

// index from 1
function GetLayerAndIndexOnLayer(cubeIndex: number, getCubesCountOnLayer: (layer: number) => number)
    : {layer: number, indexOnLayer: number} {
    let layer = 1;
    let cubesCountOnPreviousLayers = 0;
    while (true) {
        const cubesCountOnLayer = getCubesCountOnLayer(layer)
        if (cubesCountOnPreviousLayers + cubesCountOnLayer >= cubeIndex)
            break;
        layer++;
        cubesCountOnPreviousLayers += cubesCountOnLayer;
    }
    return {layer: layer, indexOnLayer: cubeIndex - cubesCountOnPreviousLayers}
}