import { connect } from 'react-redux';
import TeamInfoPage from '../components/TeamInfoPage';
import IStore from '../models/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { editTeam } from '../actions/team';
import createEmptyAction from '../utils/createEmptyAction';
import RegistrationTeam from '../models/RegistrationTeam';

const mapState = (store: IStore) => {
    return {
        team: store.team.team,
        loading: store.team.teamLoading,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        editTeam: (team: RegistrationTeam) => dispatch(editTeam(createEmptyAction(team))),
    }
}


export default connect(mapState, mapDispatch)(TeamInfoPage);
