import { connect } from 'react-redux';
import TestingPage from '../components/TestingPage';
import IStore from '../models/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { loadOtherTeamSubmissions, loadSubmissions, runNonRatingGame } from '../actions/submissions';
import createEmptyAction from '../utils/createEmptyAction';


const mapState = (store: IStore) => {
    return {
        team: store.team.team,
        submissions: store.submissions.submissions,
        otherTeams: store.submissions.otherTeamSubmissions,
        justStarted: store.submissions.justStarted,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        loadSubmissions: () => dispatch(loadSubmissions()),
        loadOtherTeamSubmissions: () => dispatch(loadOtherTeamSubmissions()),
        runNonRatingGame: (obj:{attacker: string, defender: string}) => dispatch(runNonRatingGame(createEmptyAction(obj)))
    }
}


export default connect(mapState, mapDispatch)(TestingPage);
