import React from 'react';
import styles from './styles.module.css';
import { RouteComponentProps, Link } from 'react-router-dom';

interface TabsProps {
    tabs: {
        hash: string;
        text: string;
    }[];
    location: RouteComponentProps['location'];
}

export default function Tabs(props: TabsProps) {
    const hash = props.location.hash;
    return (
        <nav className={ styles.root }>
            <ul className={ styles.list }>
                { props.tabs.map(tab =>
                    <li className={ styles.listItem } key={ tab.text }>
                        <Link to={ tab.hash }
                              className={ styles.link + (hash === tab.hash ? ' ' + styles.active : '') }
                        >{ tab.text }</Link>
                    </li>
                ) }
            </ul>
        </nav>
    )
}
