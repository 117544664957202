import React from 'react';
import './flag-icons.css';
import Team from '../../models/Team';
import { ITotalScoreboard, ILightningScoreboard, IFinalScoreboard } from '../../models/Scoreboard';
import countriesJson from '../../consts/countries.json';
import ICountry from '../../models/Country';
import { formatter } from "../DateTime/DateTime";
import { scoreboardTabs } from '../../consts/routes';
import Tabs from '../Tabs';
import { RouteComponentProps } from 'react-router-dom';
import TeamDto from '../../models/TeamDto';
import LightningScoreboard from './LightningScoreboard';
import TotalScoreboard from './TotalScoreboard';
import FinalScoreboard from './FinalScoreboard';

interface ScoreboardPageProps {
    team: Team | null;
    location: RouteComponentProps['location'];
    lightningScoreboard: ILightningScoreboard | null;
    totalScoreboard: ITotalScoreboard | null;
    finalScoreboard: IFinalScoreboard | null;
    loadProblemScoreboard: () => void;
    loadTotalScoreboard: () => void;
    loadFinalScoreboard: () => void;
}

export enum SortDirection {
    increase = 'increase',
    decrease = 'decrease',
}

const tabs = [
    { text: 'Lightning', hash: scoreboardTabs.lightning },
    { text: 'Full Round', hash: scoreboardTabs.full },
    { text: 'Final', hash: scoreboardTabs.final },
]


const countries = countriesJson as ICountry[];

export default class ScoreboardPage extends React.Component<ScoreboardPageProps> {
    componentDidMount() {
        this.props.loadProblemScoreboard();
        this.props.loadTotalScoreboard();
        this.props.loadFinalScoreboard();
    }

    render() {
        const { location, totalScoreboard, lightningScoreboard, finalScoreboard } = this.props;
        const hash = location.hash;
        return (
            <div>
                <Tabs tabs={ tabs } location={ location }/>
                { hash === scoreboardTabs.lightning && lightningScoreboard &&
                <LightningScoreboard team={ this.props.team } scoreboard={ lightningScoreboard }/> }
                { (!hash || hash === scoreboardTabs.full) && totalScoreboard &&
                <TotalScoreboard team={ this.props.team } scoreboard={ totalScoreboard }/> }
                { hash === scoreboardTabs.final && finalScoreboard &&
                <FinalScoreboard team={ this.props.team } scoreboard={ finalScoreboard }/> }
            </div>
        )
    }

    static renderTeamName(team: TeamDto, index: number = -1) {
        const itemCountry = team.customData.country;
        const country = countries.find(c => c.value === itemCountry);
        const name = team.teamName === 'The Cat is #1!!' && index !== -1 ? 'The Cat is #1!!'.replace('1', `${ index }`) : team.teamName
        return (
            <td><span
                className={ `flag-icons ${ country?.shortValue }` }
                title={ country?.text }
            />&nbsp;{ name }</td>
        )
    }


    static createDate(dateStr: string) {
        const date = new Date(dateStr);
        return formatter.format(date);
    }

}
