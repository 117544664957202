import React, { ChangeEvent } from 'react';
import styles from './styles.module.css';
import Submission, { SubmissionStatus } from '../../models/Submission';
import TeamSubmissionDto from '../../models/TeamSubmissionDto';
import Team from '../../models/Team';
import PlayIcon from '../PlayIcon';
import Toast from '../Toast';
import { Link } from 'react-router-dom';
import { gameTabs, ROUTES } from '../../consts/routes';

interface TestingPageProps {
    team: Team | null;
    submissions: Submission[] | null;
    otherTeams: TeamSubmissionDto[] | null;
    justStarted: boolean;
    loadSubmissions: () => void;
    loadOtherTeamSubmissions: () => void;
    runNonRatingGame: (obj: { attacker: string, defender: string }) => void;
}

interface TestingPageState {
    attacker: string | null;
    defender: string | null;
}

export default class TestingPage extends React.Component<TestingPageProps, TestingPageState> {
    state: TestingPageState = {
        attacker: null,
        defender: null,
    }

    componentDidMount() {
        const { submissions, loadSubmissions, loadOtherTeamSubmissions } = this.props;
        if (!submissions) {
            loadSubmissions();
        }
        loadOtherTeamSubmissions();
    }

    render() {
        const { justStarted } = this.props;
        const { attacker, defender } = this.state;
        return (
            <div className={ styles.root }>
                { this.renderUserSubmissions() }
                { this.renderOtherTeamsSubmissions() }
                { (attacker || defender) && <Toast message={ this.renderToastContent() }/> }
                { (justStarted && !attacker && !defender) && <Toast message={ this.renderSuccessToastContent() }/> }
            </div>
        )
    }

    renderUserSubmissions() {
        const { submissions } = this.props;
        return (
            <div>
                <h3>Your submissions</h3>
                <table className={ styles.table }>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Hash</th>
                        <th>Commit message</th>
                        <th>Choose</th>
                    </tr>
                    </thead>
                    <tbody>
                    { submissions?.filter(s => s.status === SubmissionStatus.Succeeded)
                        .map(this.renderSubmissionRow) }
                    </tbody>
                </table>
            </div>
        )
    }

    renderSubmissionRow = (s: Submission) => {
        return (
            <tr key={ s.commitHash + s.createdAt }>
                <td>{ s.submissionId }</td>
                <td>{ s.commitHash?.substr(0, 6) }</td>
                <td className={ styles.pre }>{ s.commitMessage }</td>
                <td className={ styles.buttons }>
                    { this.renderRunButton(s.submissionId, 'attacker') }
                    { this.renderRunButton(s.submissionId, 'defender') }
                </td>
            </tr>
        )
    }

    renderOtherTeamsSubmissions() {
        const { otherTeams } = this.props;
        return (
            <div>
                <h3>Other</h3>
                <table className={ styles.table }>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Team name</th>
                        <th>Choose</th>
                    </tr>
                    </thead>
                    <tbody>
                    { otherTeams?.map((s, i) => this.renderOtherTeamSubmissionRow(s, i, this.props.team)) }
                    </tbody>
                </table>
            </div>
        )
    }

    renderOtherTeamSubmissionRow(s: TeamSubmissionDto, index: number, team: Team | null) {
        if (team && team.teamId === s.team.teamId) {
            return null;
        }
        return (
            <tr key={ s.team.teamId }>
                <td>{ index + 1 }</td>
                <td>{ s.team.teamName }</td>
                <td className={ styles.buttons }>
                    { this.renderRunButton(s.submissionId, 'attacker') }
                    { this.renderRunButton(s.submissionId, 'defender') }
                </td>
            </tr>
        )
    }

    renderRunButton(id: string, role: 'attacker' | 'defender') {
        const chosen = this.state[role];
        const isChecked = id.toString() === chosen;
        const iconColor = role === 'attacker' ? 'orange' : 'teal';
        return (
            <label className={ styles.chooseButton } title={ `play as ${ role }` }>
                <input type="radio"
                       name={ role }
                       value={ id }
                       onChange={ role === 'attacker' ? this.chooseAttacker : this.chooseDefender }
                       checked={ isChecked }
                       className={ styles.radio }/>
                <PlayIcon color={ (!chosen || isChecked) ? iconColor : 'grey' }/>
            </label>
        )
    }

    renderToastContent() {
        const { attacker, defender } = this.state;
        return (
            <div>
                { this.createToastText() }
                <button className={ styles.toastButton } onClick={ this.clearSelection }>cancel</button>
                { attacker && defender &&
                <button className={ styles.toastButton } onClick={ this.runSelection }>run</button>
                }
            </div>
        )
    }

    renderSuccessToastContent() {
        return (
            <div>
                Non Rating game is running. It will be <Link to={ ROUTES.games + gameTabs.nonRating }>here</Link>
            </div>
        )
    }

    chooseDefender = (evt: ChangeEvent<HTMLInputElement>) => {
        const newValue = evt.target.value;
        this.setState({ defender: newValue })
    }

    chooseAttacker = (evt: ChangeEvent<HTMLInputElement>) => {
        const newValue = evt.target.value;
        this.setState({ attacker: newValue })
    }

    createToastText() {
        const { attacker, defender } = this.state;
        let text = 'You choose ';

        if (attacker) {
            text += `${ this.createSubmissionText(attacker) } as attacker`;
        }
        if (attacker && defender) {
            text += ' and ';
        }

        if (defender) {
            text += `${ this.createSubmissionText(defender) } as defender`;
        }

        return text;
    }

    createSubmissionText(id: string) {
        const { submissions, otherTeams } = this.props;

        const inMyBots = submissions?.find(i => i.submissionId.toString() === id);
        const inOtherBots = otherTeams?.find(i => i.submissionId.toString() === id);

        if (inMyBots) {
            return `your submission #${ id }`
        }
        if (inOtherBots) {
            return `team ${ inOtherBots.team.teamName }#${ id } submission`
        }
        return `submission #${ id }`;
    }

    clearSelection = () => {
        this.setState({
            defender: null,
            attacker: null,
        })
    }

    runSelection = () => {
        const { attacker, defender } = this.state;

        if (!attacker || !defender) {
            return;
        }

        this.props.runNonRatingGame({ attacker, defender });
        this.setState({ attacker: null, defender: null })
    }
}
