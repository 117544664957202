import { connect } from 'react-redux';
import GamesPage from '../components/GamesPage';
import IStore from '../models/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { loadNonRatingGames, loadRatingGames } from '../actions/games';

const mapState = (state: IStore) => {
    return {
        team: state.team.team,
        ratingGames: state.games.rating?.games,
        nonRatingGames: state.games.nonRating?.games,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        loadRatingGames: () => dispatch(loadRatingGames()),
        loadNonRatingGames: () => dispatch(loadNonRatingGames())
    }
}


export default connect(mapState, mapDispatch)(GamesPage);
