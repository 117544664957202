import { connect } from 'react-redux';
import ScoreboardPage from '../components/ScoreboardPage';
import IStore from '../models/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { loadFinalScoreboard, loadLightningScoreboard, loadTotalScoreboard } from '../actions/scoreboard';

const mapState = (store: IStore) => {
    return {
        team: store.team.team,
        lightningScoreboard: store.scoreboard.lightningScoreboard,
        totalScoreboard: store.scoreboard.totalScoreboard,
        finalScoreboard: store.scoreboard.finalScoreboard,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        loadProblemScoreboard: () => dispatch(loadLightningScoreboard()),
        loadTotalScoreboard: () => dispatch(loadTotalScoreboard()),
        loadFinalScoreboard: () => dispatch(loadFinalScoreboard()),
    }
}


export default connect(mapState, mapDispatch)(ScoreboardPage);
