export const SET_ERROR = 'SET_ERROR';

export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const LOAD_TEAM = 'LOAD_TEAM';

export const LOAD_SUBMISSIONS = 'LOAD_SUBMISSIONS';
export const UPDATE_LAST_SEEN_SUBMISSION = 'UPDATE_LAST_SEEN_SUBMISSION';
export const ACTIVATE_SUBMISSION = 'ACTIVATE_SUBMISSION';
export const LOAD_OTHER_TEAMS_SUBMISSION = 'LOAD_OTHER_TEAMS_SUBMISSION';

export const LOAD_PROBLEM_SCOREBOARD = 'LOAD_PROBLEM_SCOREBOARD';
export const LOAD_TOTAL_SCOREBOARD = 'LOAD_SCOREBOARD';
export const LOAD_FINAL_SCOREBOARD = 'LOAD_FINAL_SCOREBOARD';

export const LOAD_CURRENT_TOURNAMENT = 'LOAD_CURRENT_TOURNAMENT';

export const RUN_NON_RATING_GAME = 'RUN_NON_RATING_GAME';
export const LOAD_RATING_GAMES = 'LOAD_RATING_GAMES';
export const LOAD_NON_RATING_GAMES = 'LOAD_NON_RATING_GAMES';

export const START = "_START";
export const SUCCESS = "_SUCCESS";
export const FAIL = "_FAIL";
export const FINISH = "_FINISH";
