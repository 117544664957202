import TeamDto from './TeamDto';

export default interface GameDto {
    gameId: string;
    tournamentId?: number;
    tournamentRoundId?: number;
    createdAt?: string;
    finishedAt?: string;
    ticks?: number;
    attacker: PlayerDto;
    defender: PlayerDto;
    winner?: GameWinner;
}

export interface PlayerDto {
    submissionId: string;
    team: TeamDto;
    debugLog?: string;
    playerKey?: string;
    timeout?: boolean;
}

export enum GameWinner {
    Nobody = 'Nobody',
    Attacker = 'Attacker',
    Defender = 'Defender',
}
