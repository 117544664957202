import { connect } from 'react-redux';
import RegistrationPage from '../components/RegistrationPage';
import IStore from '../models/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { register } from '../actions/team';
import createEmptyAction from '../utils/createEmptyAction';
import RegistrationTeam from '../models/RegistrationTeam';

const mapState = (store: IStore) => {
    return {
        loading: store.team.teamLoading,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        register: (team: RegistrationTeam) => dispatch(register(createEmptyAction(team))),
    }
}


export default connect(mapState, mapDispatch)(RegistrationPage);
