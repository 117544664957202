export type ILogData = [0 | 1, ApiGameType, ApiGameStatus, number, number[][], any[]];

export interface IGameLogs {
    isSucceeded: 0 | 1;
    gameType: ApiGameType;
    gameStatus: ApiGameStatus;
    ticks: number;
    players: IPlayer[];
    gameLog?: ApiGameLog
}

export enum ApiGameType {
    AttackDefense = 0,
    Tutorial_Fly = 1,
    Tutorial_Detonate = 2,
    Tutorial_Burn = 3,
    Tutorial_BurnWithOverheat = 4,
    Tutorial_Radiators = 5,
    Tutorial_ShootOne = 6,
    Tutorial_ShootZeroTempTarget = 7,
    Tutorial_ShootAttackers = 8,
    Tutorial_FlyAndShootTarget = 9,
    Tutorial_Split = 10,
    Tutorial_Planet = 11,
    Tutorial_ShootSatellite = 12,
    Tutorial_KillDefenderBoss = 13,
}

export enum ApiGameStatus {
    New = 0,
    Joined = 1,
    InProgress = 2,
    Finished = 3
}

export interface IPlayer {
    role: ApiPlayerRole;
    score: number;
    status: ApiPlayerStatus;
}

export enum ApiPlayerStatus {
    NotJoined = 0,
    Thinking = 1,
    ReadyToGo = 2,
    Won = 3,
    Lost = 4,
    Tied = 5,
}

export interface ApiGameLog {
    planet?: IPlanet;
    ticks: ITick[];
}

export interface IPlanet {
    radius: number;
    safeRadius: number;
}

export interface ITick {
    tick: number;
    ships: IShipAndCommands[];
}

export interface IShipAndCommands {
    ship: IShip;
    appliedCommands: ICommand[];
}

export interface IShip {
    role: ApiPlayerRole;
    shipId: number;
    position: V;
    velocity: V;
    matter: IMatter;
    temperature: number;
    criticalTemperature: number;
    maxFuelBurnSpeed: number;
}

export interface V {
    x: number;
    y: number;
}

export interface IMatter {
    fuel: number;
    lasers: number;
    radiators: number;
    engines: number;
}

export type ICommand = IDetonateCommand | ILaserCommand | IBurnFuelCommand | ISplitCommand;

export interface IDetonateCommand {
    type: ApiCommandType.Detonate;
    power: number;
    powerDecreaseStep: number;
}

export interface ILaserCommand {
    type: ApiCommandType.Shoot;
    target: V;
    power: number;
    damage: number;
    damageDecreaseFactor: number;
}

export interface IBurnFuelCommand {
    type: ApiCommandType.BurnFuel;
    velocity: V;
}

export interface ISplitCommand {
    type: ApiCommandType.SplitShip;
    matter: IMatter;
}

export enum ApiCommandType {
    BurnFuel = 0,
    Detonate = 1,
    Shoot = 2,
    SplitShip = 3
}

export enum ApiPlayerRole {
    Attacker = 0,
    Defender = 1,
}