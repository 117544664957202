import { SET_ERROR } from '../consts/actions';
import { ThunkAction } from 'redux-thunk';
import IStore from '../models/store';
import IAppAction from '../models/actions/app';
import { Dispatch } from 'redux';

export const createSetErrorAction = (error: string | Error | null) => ({
    type: SET_ERROR,
    payload: error,
})

export function setErrorAction(action: { payload: string | Error | null }):
    ThunkAction<void, IStore, unknown, IAppAction>  {
    return (dispatch: Dispatch) => {
        dispatch(createSetErrorAction(action.payload));
    }
}
