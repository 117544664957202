import { FINISH, LOAD_TEAM, START, UPDATE_API_KEY, UPDATE_TEAM } from '../consts/actions';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import IStore from '../models/store';
import ITeamAction from '../models/actions/team';
import Team from '../models/Team';
import { sendGetRequest, sendPatchFormRequest, sendPostFormRequest } from '../utils/sendRequest';
import { currentTeam, registration } from '../consts/api';
import { createSetErrorAction } from './app';
import { API_KEY } from '../consts/strings';
import RegistrationTeam from '../models/RegistrationTeam';
import createFormData from '../utils/createFormData';
import createQueryString from '../utils/createQueryString';

const createUpdateApiKeyAction = (apiKey: string | null) => ({
    type: UPDATE_API_KEY,
    payload: apiKey,
})

const createUpdateTeamAction = (team: Team | null) => ({
    type: UPDATE_TEAM,
    payload: team,
})

const createLoadTeamStartAction = () => ({
    type: LOAD_TEAM + START,
})

const createLoadTeamFinishAction = () => ({
    type: LOAD_TEAM + FINISH,
})

export function updateApiKey(action: { payload: string | null }):
    ThunkAction<void, IStore, unknown, ITeamAction> {
    return (dispatch: Dispatch) => {
        dispatch(createUpdateApiKeyAction(action.payload));
    }
}

export function updateTeam(action: { payload: Team | null }):
    ThunkAction<void, IStore, unknown, ITeamAction> {
    return (dispatch: Dispatch) => {
        dispatch(createUpdateTeamAction(action.payload))
    }
}

export function loadTeam():
    ThunkAction<Promise<any>, IStore, unknown, ITeamAction> {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(createLoadTeamStartAction());
        const store = getState();
        const apiKey = store.team.apiKey;
        const requestUrl = `${ currentTeam }?${ createQueryString({ apiKey }) }`;
        try {
            const team = await sendGetRequest(requestUrl);
            dispatch(createUpdateTeamAction(team));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        } finally {
            dispatch(createLoadTeamFinishAction());
        }
    }
}

export function login(action: { payload: string }):
    ThunkAction<Promise<any>, IStore, unknown, ITeamAction> {
    return async (dispatch: Dispatch) => {
        dispatch(createLoadTeamStartAction());
        const requestUrl = `${ currentTeam }?${ createQueryString({ apiKey: action.payload }) }`
        try {
            const team = await sendGetRequest(requestUrl);
            window.localStorage.setItem(API_KEY, action.payload);
            dispatch(createUpdateApiKeyAction(action.payload));
            dispatch(createUpdateTeamAction(team));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        } finally {
            dispatch(createLoadTeamFinishAction());
        }
    }
}

export function register(action: { payload: RegistrationTeam }):
    ThunkAction<Promise<any>, IStore, unknown, ITeamAction> {
    return async (dispatch: Dispatch) => {
        dispatch(createLoadTeamStartAction());
        const body = createFormData(action.payload);
        try {
            const team = await sendPostFormRequest(registration, body);
            window.localStorage.setItem(API_KEY, team.apiKey);
            dispatch(createUpdateApiKeyAction(team.apiKey));
            dispatch(createUpdateTeamAction(team));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        } finally {
            dispatch(createLoadTeamFinishAction());
        }
    }
}

export function editTeam(action: { payload: RegistrationTeam }):
    ThunkAction<Promise<any>, IStore, unknown, ITeamAction> {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(createLoadTeamStartAction());
        const store = getState();
        const apiKey = store.team.apiKey;
        const requestUrl = `${ currentTeam }?${ createQueryString({ apiKey }) }`;
        const body = createFormData(action.payload);
        try {
            const team = await sendPatchFormRequest(requestUrl, body);
            dispatch(createUpdateTeamAction(team));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        } finally {
            dispatch(createLoadTeamFinishAction());
        }
    }
}
