import React from 'react';
import styles from './styles.module.css';
import ScoreboardPage from './ScoreboardPage';
import Team from '../../models/Team';
import {
    IFinalScoreboard, IFinalScoreboardTeam,
} from '../../models/Scoreboard';


interface FinalScoreboardProps {
    team: Team | null;
    scoreboard: IFinalScoreboard;
}

export default class FinalScoreboard extends React.Component<FinalScoreboardProps> {
    render() {
        const { scoreboard, team } = this.props;

        return (
            <>
                <h2>Scoreboard{ team && ': ' + team.teamName }</h2>
                <table className={ styles.table }>
                    { this.renderHead() }
                    <tbody>
                    { scoreboard.teams && scoreboard.teams.map(this.renderRow) }
                    </tbody>
                </table>
            </>
        )
    }

    renderHead() {
        const { scoreboard, team } = this.props;

        return (
            <thead>

            <tr>
                <th style={ { width: 40 } }>#</th>
                <th className={ styles.alignLeft }>Team Name</th>
                <th>Platform</th>
                <th style={ { width: 70 } }
                    className={ styles.score }>
                    Score
                </th>
            </tr>
            { scoreboard.teams && scoreboard.teams
                .map((team, i) => ({ team, i }))
                .filter(tuple => tuple.team.team.teamId === team?.teamId && tuple.i > 5)
                .map(tuple => this.renderRow(tuple.team, tuple.i))
            }
            </thead>
        )
    }

    renderRow = (scoreboardTeam: IFinalScoreboardTeam, index: number) => {
        const { team } = this.props;

        const teamInfo = scoreboardTeam.team;
        return (
            <tr key={ index } className={ team?.teamId === teamInfo.teamId ? styles.activeTeam : '' }>
                <td className={ styles.alignCenter }>{ index + 1 }</td>
                { ScoreboardPage.renderTeamName(teamInfo) }
                <td>{ scoreboardTeam.submission.platform }</td>
                <td className={ styles.score }>{ typeof scoreboardTeam.score === 'number' ? scoreboardTeam.score : '?' }</td>
            </tr>
        )
    }

}
