import { LOAD_NON_RATING_GAMES, LOAD_RATING_GAMES } from '../consts/actions';
import { ThunkAction } from 'redux-thunk';
import IStore from '../models/store';
import IAppAction from '../models/actions/app';
import { Dispatch } from 'redux';
import GamesListDto from '../models/GamesListDto';
import { currentTournament, games, nonRatingGames } from '../consts/api';
import createQueryString from '../utils/createQueryString';
import { sendGetRequest } from '../utils/sendRequest';
import { createSetErrorAction } from './app';
import TournamentDto from '../models/TournamentDto';
import { createLoadCurrentTournamentAction } from './tournament';

export const createLoadRatingGamesAction = (games: GamesListDto) => ({
    type: LOAD_RATING_GAMES,
    payload: games,
})

export const createLoadNonRatingGamesAction = (games: GamesListDto) => ({
    type: LOAD_NON_RATING_GAMES,
    payload: games,
})

export function loadRatingGames():
    ThunkAction<Promise<any>, IStore, unknown, IAppAction>  {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();
        const apiKey = store.team.apiKey;
        const tournament = store.tournament.current

        try {
            let tournamentId;

            if (!tournament) {
                const result: TournamentDto = await sendGetRequest(currentTournament);
                dispatch(createLoadCurrentTournamentAction(result));
                tournamentId = result.tournamentId;
            } else {
                tournamentId = tournament.tournamentId;
            }

            const requestUrl = `${ games }?${ createQueryString({ apiKey, tournamentId }) }`
            const result = await sendGetRequest(requestUrl);
            dispatch(createLoadRatingGamesAction(result));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}

export function loadNonRatingGames():
    ThunkAction<Promise<any>, IStore, unknown, IAppAction>  {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();
        const apiKey = store.team.apiKey;
        const tournament = store.tournament.current

        try {
            let tournamentId;

            if (!tournament) {
                const result: TournamentDto = await sendGetRequest(currentTournament);
                dispatch(createLoadCurrentTournamentAction(result));
                tournamentId = result.tournamentId;
            } else {
                tournamentId = tournament.tournamentId;
            }

            const requestUrl = `${ nonRatingGames }?${ createQueryString({ apiKey, tournamentId }) }`
            const result = await sendGetRequest(requestUrl);
            dispatch(createLoadNonRatingGamesAction(result));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}
