import { combineReducers } from "redux";
import app from './app';
import team from './team';
import submissions from './submissions';
import scoreboard from './scoreboard';
import tournament from './tournament';
import games from './games';

const rootReducer = combineReducers({
    app,
    team, 
    submissions,
    scoreboard,
    tournament,
    games,
})

export default rootReducer;
