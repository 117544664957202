const json = 'application/json';
const form = 'application/x-www-form-urlencoded';

export function sendGetRequest(url: string) {
    return sendRequest(url, {});
}

export function sendPostRequest(url: string, body: object | null) {
    return sendRequest(url, {
        method: 'POST',
        headers: {
            'Content-Type': json,
        },
        body: body ? JSON.stringify(body) : undefined,
    })
}

export function sendPostFormRequest(url: string, body: string) {
    return sendRequest(url, {
        method: 'POST',
        headers: {
            'Content-Type': form,
        },
        body: body,
    })
}

export function sendPatchFormRequest(url: string, body: string) {
    return sendRequest(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': form,
        },
        body: body,
    })
}

function sendRequest(url: string, params: RequestInit) {
    return fetch(url, params).then(async resp => {
        const contentType = resp.headers.get('Content-Type')?.split(';')[0];

        if (resp.ok && contentType === json) {
            return resp.json();
        }

        if (resp.ok) {
            return;
        }
        
        if (contentType === json) {
            const errorJson = await resp.json();
            return Promise.reject(errorJson.message || errorJson.error || JSON.stringify(errorJson));
        }
        
        const errMessage = `Something went wrong. Server answer is ${ resp.status }${ 
            resp.statusText ? ':' + resp.statusText : '' }`
        return Promise.reject(errMessage);
    });
}
