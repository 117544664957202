import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './styles.module.css';
import { blogTabs, gameTabs, ROUTES, scoreboardTabs } from '../../consts/routes';
import Team from '../../models/Team';

type HeaderProps = {
    team: Team | null;
    isTeamLoading: boolean;
    logout: () => void;
}

const unauthLinks = [
    { url: ROUTES.registration, content: 'Sign up' },
    { url: ROUTES.login, content: 'Log in', },
    { url: ROUTES.blog, content: 'Blog' },
    { url: ROUTES.scoreboard + scoreboardTabs.final, content: 'Scoreboard' }
]

const authLinks = [
    { url: ROUTES.teamInfo, content: 'Profile' },
    { url: ROUTES.submissions, content: 'Submissions' },
    { url: ROUTES.testing, content: 'Testing' },
    { url: ROUTES.games + gameTabs.rating, content: 'Games' },
    { url: ROUTES.blog + blogTabs.orgs, content: 'Blog' },
    { url: ROUTES.scoreboard + scoreboardTabs.final, content: 'Scoreboard' },
]

export default function Header(props: HeaderProps) {
    const isLoggedIn = Boolean(props.team);
    return (
        <header className={ styles.root }>
            <h1>
                <Link to='/' className={ styles.titleLink }>ICFP Programming Contest 2020</Link>
                <div className={ styles.subtitle }>
                    started on July 17, 2020 · ended on July 20, 2020
                </div>
            </h1>
            { !props.isTeamLoading &&
            <>
                { isLoggedIn ? renderAuthorizedLinks(props) : renderUnauthorizedLinks() }
            </>
            }
        </header>
    )
}


function renderLinks(links: typeof unauthLinks) {
    return <>
        { links.map(link =>
            <NavLink to={ link.url } className={ styles.link } key={ link.url } activeClassName={ styles.activeLink }>
                { link.content }
            </NavLink>
        ) }
    </>
}

function renderUnauthorizedLinks() {
    return (
        <div className={ styles.links }>
            { renderLinks(unauthLinks) }
        </div>
    )
}

function renderAuthorizedLinks(props: HeaderProps) {
    const { team } = props;
    if (!team) return;
    return (
        <div className={styles.navMenuLinks}>
            { renderLinks(authLinks) }
        </div>
    )
}
