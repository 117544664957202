import React from 'react';
import styles from './styles.module.css';
import { ROUTES } from "../../../consts/routes";
import { Link } from "react-router-dom";

export default function LandingPage() {
    return (
        <div className={ styles.root }>
            <section className={ styles.video }>
                <div className={ styles.subtitle }>
                    Many thanks to all the participants!<br/>
                    Watch the video and read the <Link to={ROUTES.post + '/2061'}
                                                   target="_blank" rel="noopener noreferrer">wrapping up blog post</Link>
                </div>
                <iframe title="title-video" width="666" height="375" src="https://www.youtube.com/embed/Me-0k5XGZWY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
            </section>

            <section className={ styles.section }>
                <ul className={ styles.links }>
                    <li>
                        <p>Follow <a href="https://twitter.com/icfpcontest2020" target="_blank" rel="noopener noreferrer">Twitter</a> for updates, talk in <a href="https://discord.com/invite/xvMJbas" target="_blank" rel="noopener noreferrer">Discord</a>.</p>
                    </li>
                    <li>
                        <p>(Also, we had these <Link to={ ROUTES.rules } target="_blank">rules</Link>.)</p>
                    </li>
                </ul>
            </section>

            <section className={ styles.section }>
                <h2>Something to compete for</h2>

                <ul className={ styles.features }>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">🤪</span>
                        </div>
                        <div className={ styles.features__title }>Fun & joy.</div>
                        <div>These are noble feelings to pursue during the contest</div>
                    </li>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">🎖</span>
                        </div>
                        <div className={ styles.features__title }>1st @ lightning division.</div>
                        <div>One team will take the 1st place after 24 hours</div>
                        <div className={ styles.features__pro }>Their tools will be named very suitable for rapid prototyping</div>
                    </li>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">🏅</span>
                        </div>
                        <div className={ styles.features__title }>Judges' prize.</div>
                        <div>One team which will do something outstanding will take the judges' prize</div>
                        <div className={ styles.features__pro }>This team will be named an extremely cool bunch of hackers</div>
                    </li>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">🥇</span>
                        </div>
                        <div className={ styles.features__title }>1st @ full contest.</div>
                        <div>One team will take the 1st place after 72 hours</div>
                        <div className={ styles.features__pro }>Their tools will be named programming tools of choice for discriminating hackers</div>
                    </li>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">🥈</span>
                        </div>
                        <div className={ styles.features__title }>2nd @ full contest.</div>
                        <div>One team will take the 2nd place after 72 hours</div>
                        <div className={ styles.features__pro }>Their tools will be named fine programming tools for many applications</div>
                    </li>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">🥉</span>
                        </div>
                        <div className={ styles.features__title }>3rd @ full contest.</div>
                        <div>One team will take the 3rd place after 72 hours</div>
                        <div className={ styles.features__pro }>Their tools will be named not too shabby</div>
                    </li>
                </ul>
            </section>

            <section className={ styles.section }>
                <h2>Look what has happened at Pegovka Observatory</h2>

                <ul className={ styles.features }>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">😱🙀</span>
                        </div>
                        <div>
                            Read through a <a href="https://pegovka.space" target="_blank" rel="noopener noreferrer">surprising story</a> of Russian astronomer Ivan Zaitsev
                        </div>
                    </li>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">👩‍💻👨‍💻</span>
                        </div>
                        <div>
                            Join the <a href="https://discord.com/invite/xvMJbas" target="_blank" rel="noopener noreferrer">collective chat</a> with passionate participants
                        </div>
                    </li>
                    <li>
                        <div className={ styles.features__emoji }>
                            <span role="img" aria-label="">📈📉</span>
                        </div>
                        <div>
                            Recollect what's already known via the <a href="https://message-from-space.readthedocs.io/en/latest/" target="_blank" rel="noopener noreferrer">Read the Docs</a> page
                        </div>
                    </li>
                </ul>

                <ul className={ styles.videos }>
                    <li>
                        <iframe title="first-video" width="300" height="170" src="https://www.youtube.com/embed/EjL-5EuQeCU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                    </li>
                    <li>
                        <iframe title="second-video" width="300" height="170" src="https://www.youtube.com/embed/j7kVUaZCcMU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                    </li>
                    <li>
                        <iframe title="title-video" width="300" height="170" src="https://www.youtube.com/embed/PzJ6gfo92oA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                    </li>
                </ul>
            </section>

            <section className={ styles.section }>
                <h2>Special thanks</h2>

                <ul className={ styles.thanks }>
                    <li>
                        <div className={ styles.thanks__title }>
                            <a href="https://www.icfpconference.org" target="_blank" rel="noopener noreferrer">ICFP</a>
                        </div>
                        <div className={ styles.thanks__script }>
                            for sponsoring ICFP Contest and making it happen for 23 years in a row
                        </div>
                    </li>
                    <li>
                        <div className={ styles.thanks__title }>
                            <a href="https://tech.kontur.ru" target="_blank" rel="noopener noreferrer">Kontur</a>
                        </div>
                        <div className={ styles.thanks__script }>
                            for sponsoring ICFP Contest 2020 and employing the team of its organizers
                        </div>
                    </li>
                </ul>
            </section>

            <section className={ styles.section }>
                <h2>Warm wishes to our supporters</h2>

                <ul className={ styles.supporters }>
                    <li>
                        <div className={ styles.supporters__title }>
                            <a href="https://adventofcode.com" target="_blank" rel="noopener noreferrer">Advent of Code</a>
                        </div>
                        <div className={ styles.supporters__script }>
                            and its creator <a href="https://twitter.com/ericwastl" target="_blank" rel="noopener noreferrer">Eric&nbsp;Wastl</a>
                        </div>
                    </li>
                    <li>
                        <div className={ styles.supporters__title }>
                            <a href="https://atcoder.jp" target="_blank" rel="noopener noreferrer">AtCoder</a>
                        </div>
                        <div className={ styles.supporters__script }>
                            and its founder <a href="https://twitter.com/chokudai" target="_blank" rel="noopener noreferrer">Naohiro&nbsp;Takahashi</a>
                        </div>
                    </li>
                    <li>
                        <div className={ styles.supporters__title }>
                            <a href="https://codeforces.com/?locale=en" target="_blank" rel="noopener noreferrer">Codeforces</a>
                        </div>
                        <div className={ styles.supporters__script }>
                            and its founder <a href="https://twitter.com/MikeMirzayanov" target="_blank" rel="noopener noreferrer">Mike&nbsp;Mirzayanov</a>
                        </div>
                    </li>
                    <li>
                        <div className={ styles.supporters__title }>
                            <a href="https://www.codingame.com" target="_blank" rel="noopener noreferrer">CodinGame</a>
                        </div>
                        <div className={ styles.supporters__script }>
                            and its community manager <a href="https://twitter.com/thibaud_jobert" target="_blank" rel="noopener noreferrer">Thibaud&nbsp;Jobert</a>
                        </div>
                    </li>
                    <li>
                        <div className={ styles.supporters__title }>
                            <a href="https://devzen.ru" target="_blank" rel="noopener noreferrer">DevZen Podcast</a>
                        </div>
                        <div className={ styles.supporters__script }>
                            and its hosts <a href="https://twitter.com/SBozhko" target="_blank" rel="noopener noreferrer">Sveta</a>, <a href="https://twitter.com/afiskon" target="_blank" rel="noopener noreferrer">Aleksander</a>, <a href="https://twitter.com/gliush" target="_blank" rel="noopener noreferrer">Ivan</a>, and&nbsp;<a href="https://twitter.com/sum3rman" target="_blank" rel="noopener noreferrer">Valery</a>
                        </div>
                    </li>
                    <li>
                        <div className={ styles.supporters__title }>
                            <a href="https://it-people.ru" target="_blank" rel="noopener noreferrer">IT People</a>
                        </div>
                        <div className={ styles.supporters__script }>
                            and its founder <a href="https://www.facebook.com/yulia.gerasimovich" target="_blank" rel="noopener noreferrer">Yulia&nbsp;Gerasimovich</a>
                        </div>
                    </li>
                    <li>
                        <div className={ styles.supporters__title }>
                            <a href="https://jugru.org/en/" target="_blank" rel="noopener noreferrer">JUG Ru Group</a>
                        </div>
                        <div className={ styles.supporters__script }>
                            and its producer <a href="https://twitter.com/23derevo" target="_blank" rel="noopener noreferrer">Alexey&nbsp;Fyodorov</a>
                        </div>
                    </li>
                    <li>
                        <div className={ styles.supporters__title }>
                            And also:
                        </div>
                        <div className={ styles.supporters__script }>
                            <a href="https://twitter.com/ilyasergey" target="_blank" rel="noopener noreferrer">Ilya&nbsp;Sergey</a>, <a href="https://twitter.com/nikitonsky" target="_blank" rel="noopener noreferrer">Nikita&nbsp;Prokopov</a>, and <a href="https://twitter.com/VBragilevsky" target="_blank" rel="noopener noreferrer">Vitaly&nbsp;Bragilevsky</a>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    )
}
