import IAppStore from '../models/store/app';
import IAppActions from '../models/actions/app';
import { SET_ERROR } from '../consts/actions';

const defaultStore: IAppStore ={
    error: null,
}

function appReducer(store = defaultStore, action: IAppActions) {
    switch (action.type) {
        case SET_ERROR: 
            return {
                ...store,
                error: action.payload,
            }
        default:
            return store;
    }
}

export default appReducer;
