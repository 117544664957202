import {
    ACTIVATE_SUBMISSION, FINISH,
    LOAD_OTHER_TEAMS_SUBMISSION,
    LOAD_SUBMISSIONS, RUN_NON_RATING_GAME, START, SUCCESS,
    UPDATE_LAST_SEEN_SUBMISSION
} from '../consts/actions';
import Submission from '../models/Submission';
import { ThunkAction } from 'redux-thunk';
import IStore from '../models/store';
import { Dispatch } from 'redux';
import ISubmissionsAction from '../models/actions/submissions';
import { sendGetRequest, sendPostRequest } from '../utils/sendRequest';
import {
    currentTournament,
    nonRatingGamesRun,
    otherTeamsSubmission,
    submissionActivate,
    submissions
} from '../consts/api';
import { createSetErrorAction } from './app';
import createQueryString from '../utils/createQueryString';
import TeamSubmissionDto from '../models/TeamSubmissionDto';
import { createLoadCurrentTournamentAction } from './tournament';
import TournamentDto from '../models/TournamentDto';

const createLoadSubmissionsAction = (submissions: Submission[]) => ({
    type: LOAD_SUBMISSIONS,
    payload: submissions,
})
const createUpdateLastSeenSubmissionAction = (submissionId: string | null) => ({
    type: UPDATE_LAST_SEEN_SUBMISSION,
    payload: submissionId,
})

const createActivateSubmissionAction = (submissionId: string) => ({
    type: ACTIVATE_SUBMISSION,
    payload: submissionId,
})

const createLoadOtherTeamsSubmissionAction = (submissions: TeamSubmissionDto) => ({
    type: LOAD_OTHER_TEAMS_SUBMISSION,
    payload: submissions,
})

const createRunNonRatingGameStartAction = () => ({
    type: RUN_NON_RATING_GAME + START,
})
const createRunNonRatingGameSuccessAction = () => ({
    type: RUN_NON_RATING_GAME + SUCCESS,
})
const createRunNonRatingGameFinishAction = () => ({
    type: RUN_NON_RATING_GAME + FINISH,
})

export function loadSubmissions():
    ThunkAction<Promise<any>, IStore, unknown, ISubmissionsAction> {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();
        const apiKey = store.team.apiKey;
        const requestUrl = `${ submissions }?${ createQueryString({ apiKey }) }`
        try {
            const teamSubmissions = await sendGetRequest(requestUrl);
            dispatch(createLoadSubmissionsAction(teamSubmissions));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}

export function updateLastSeenSubmission(action: { payload: string | null }):
    ThunkAction<void, IStore, unknown, ISubmissionsAction> {
    return (dispatch: Dispatch) => {
        dispatch(createUpdateLastSeenSubmissionAction(action.payload));
    }
}

export function activateSubmission(action: { payload: string }):
    ThunkAction<Promise<any>, IStore, unknown, ISubmissionsAction> {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();
        const apiKey = store.team.apiKey;
        const requestUrl = `${ submissionActivate(action.payload) }?${ createQueryString({ apiKey }) }`

        try {
            await sendPostRequest(requestUrl, null);
            dispatch(createActivateSubmissionAction(action.payload));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}

export function loadOtherTeamSubmissions():
    ThunkAction<Promise<any>, IStore, unknown, ISubmissionsAction> {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        const store = getState();
        const apiKey = store.team.apiKey;
        const tournament = store.tournament.current
        
        try {
            let tournamentId;
            
            if (!tournament) {
                const result: TournamentDto = await sendGetRequest(currentTournament);
                dispatch(createLoadCurrentTournamentAction(result));
                tournamentId = result.tournamentId;
            } else {
                tournamentId = tournament.tournamentId;
            }
            
            
            const requestUrl = `${ otherTeamsSubmission }?${ createQueryString({ apiKey, tournamentId }) }`;
            const result = await sendGetRequest(requestUrl);
            dispatch(createLoadOtherTeamsSubmissionAction(result));
        } catch (e) {
            dispatch(createSetErrorAction(e))
        }
    }
}

export function runNonRatingGame(action: { payload: {attacker: string, defender: string} }):
    ThunkAction<Promise<any>, IStore, unknown, ISubmissionsAction> {
    return async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch(createRunNonRatingGameStartAction());
        const store = getState();
        const apiKey = store.team.apiKey;
        const requestUrl = `${ nonRatingGamesRun }?${ createQueryString({ 
            apiKey,
            attackerSubmissionId: action.payload.attacker,
            defenderSubmissionId: action.payload.defender,
        }) }`
        
        try {
            await sendPostRequest(requestUrl, null);
            dispatch(createRunNonRatingGameSuccessAction());
            
            setTimeout(() => dispatch(createRunNonRatingGameFinishAction()), 3000)
        } catch (e) {
            dispatch(createSetErrorAction(e))
            dispatch(createRunNonRatingGameFinishAction())
        }
    }
}
