import React, { ChangeEvent, FormEvent } from 'react';
import styles from './styles.module.css';
import globalForm from '../forms.module.css';

export interface LoginPageProps {
    loading: boolean;
    login: (apiKey: string) => void;
}

type LoginPageState = {
    apiKey: string;
}

export default class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
    constructor(props: LoginPageProps) {
        super(props);
        this.state = {
            apiKey: '',
        };
    }

    render() {
        const { loading } = this.props;
        return (
            <div>
                <h2>Log In</h2>
                <form className={ `${ styles.form } ${ globalForm.form }` } onSubmit={ this.onSubmit }>
                    <label className={ styles.row }>
                        <span>API key</span>
                        <input name='apiKey'
                               required
                               autoFocus
                               onChange={ this.onChange }
                        />
                    </label>
                    <label className={ styles.buttonRow }>
                        <span/>
                        <div>
                            <button type='submit' disabled={ loading }>
                                { loading ? "Logging In..." : "Log In" }
                            </button>
                        </div>
                    </label>
                </form>
            </div>
        )
    }

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({ apiKey: e.target.value })
    }

    onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const { apiKey } = this.state;
        
        this.props.login(apiKey);
    }
}
