import IRenderer from "./IRenderer";
import {
    ApiCommandType, ApiPlayerRole,
    IBurnFuelCommand,
    IDetonateCommand,
    ILaserCommand,
    IShip,
    IShipAndCommands,
    ISplitCommand,
    V
} from "./GameLogModels";
import {GetShipComponents, GetShipComponentsCount, IShipComponent, ShipComponentType} from "./ShipsUtils";
import {
    AdditiveBlending,
    AmbientLight,
    AnimationClip,
    AnimationMixer,
    BackSide,
    BoxBufferGeometry,
    BoxGeometry,
    Color,
    ColorKeyframeTrack, ConeGeometry,
    CylinderGeometry,
    DirectionalLight,
    DoubleSide,
    FrontSide,
    InterpolateLinear,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    NumberKeyframeTrack,
    Object3D,
    PerspectiveCamera,
    Scene,
    ShaderMaterial,
    SphereGeometry, Sprite, SpriteMaterial,
    TextureLoader,
    Vector2,
    Vector3,
    VectorKeyframeTrack,
    WebGLRenderer
} from "three";
import {Texture} from "three/src/textures/Texture";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass";
import {BufferGeometryUtils} from "three/examples/jsm/utils/BufferGeometryUtils";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {FXAAShader} from "three/examples/jsm/shaders/FXAAShader";

export default class Renderer3d implements IRenderer {
    private readonly ctx: WebGLRenderingContext;
    private readonly canvas: HTMLCanvasElement;
    private readonly simulate: (ship: IShip, ticks: number, hasPlanet: boolean) => V[];
    private readonly scene: Scene;
    private readonly renderer: WebGLRenderer;
    private readonly camera: PerspectiveCamera;
    private bloomComposer: EffectComposer;

    cameraRotationLon: number = 0; // широта
    cameraRotationLat: number = 0; // долгота
    private onPointerDownPointerX: number  = 0;
    private onPointerDownPointerY: number  = 0;
    private onStartRotateLon: number = 0;
    private onStartRotateLat: number = 0;
    private cameraRotationLonDelta: number = 0;
    private cameraRotationLatDelta: number = 0;
    private dampingFactor = 0.05;
    private rotationSpeed = 0.008;

    private zoom: number = 1;
    private zoomDelta: number = 0;
    private maxZoom = 30;
    private minZoom = 0.4;
    private zoomDampingFactor = 0.02;

    private shipIdInView: number | undefined;
    isGeneralView: boolean = true;
    private lookAt: Vector3 = new Vector3();
    private cameraPosition: Vector3 = new Vector3();

    private prevShipsAndCommands: IShipAndCommands[] | undefined;
    private shipsAndCommands: IShipAndCommands[] = [];
    private nextShipsAndCommands: IShipAndCommands[] | undefined;
    notDrawShips: boolean = true;
    private shipInViewFlyClockwise: boolean = false;
    private isAutoPlay = false;

    private shipsOnScene: { [id: number] : {mesh: Mesh, sprite: Sprite, components: IShipComponent[]} } = [];
    private detonationsOnScene: { outerSphere: Mesh, innerSphere: Mesh }[] = [];
    private laserLinesOnScene: { laser: Mesh, glow: Mesh, target: Vector3, shipId: number}[] = [];
    private laserShootRadiiOnScene: { shoot: Object3D, innerSphere: Mesh, glow: Mesh }[] = [];
    private exhaustsOnScene: { mesh: Mesh, glow: Mesh, shipId: number }[] = [];
    private readonly shipCubeSize = 1 / 7 / 0.7;

    private planet: Mesh | undefined;
    private planetGlow: Mesh | undefined;

    private timePerTick: number;
    private startTime: number = +new Date();
    private lastTickTime: number = 0;

    private readonly skyTex: Texture;
    private readonly planetOpacityTex: Texture;
    private shipComponentsMaterials: {component: ShipComponentType, material: MeshPhongMaterial}[] = [];
    private readonly shipSpriteMaterial: SpriteMaterial;

    private explosionMeshes: Mesh[] = [];
    private mixers: AnimationMixer[] = [];

    private readonly fxaaPass: ShaderPass;

    constructor(ctx: WebGLRenderingContext, canvas: HTMLCanvasElement, timePerTick: number,
                simulate: (ship: IShip, ticks: number, hasPlanet: boolean) => V[]) {
        this.bind();

        this.ctx = ctx;
        this.canvas = canvas;
        this.simulate = simulate;
        this.timePerTick = timePerTick;
        this.scene = new Scene();
        this.renderer = new WebGLRenderer( { canvas: this.canvas, antialias: false } );
        this.renderer.setSize(canvas.scrollWidth, canvas.scrollHeight);

        const textureLoader = new TextureLoader();
        this.skyTex = textureLoader.load("images/Galaxy2.jpg");
        this.planetOpacityTex = textureLoader.load("images/Planet.png");

        this.camera = new PerspectiveCamera(50, canvas.scrollWidth / canvas.scrollHeight, 1, 10000);

        this.drawStarsSphere();
        this.createLight();
        this.initShipComponentsMaterials(textureLoader);
        this.shipSpriteMaterial = new SpriteMaterial({ color: 0xffffff, sizeAttenuation: false, opacity: 0.7 } );

        const renderScene = new RenderPass(this.scene, this.camera);
        const bloomPass = new UnrealBloomPass(
            new Vector2(this.canvas.width, this.canvas.height),
            0.8, 0.6, 0.3);
        this.fxaaPass = new ShaderPass(FXAAShader);

        this.bloomComposer = new EffectComposer(this.renderer);
        this.bloomComposer.renderToScreen = true;
        this.bloomComposer.addPass(renderScene);
        this.bloomComposer.addPass(bloomPass);
        this.bloomComposer.addPass(this.fxaaPass);

        this.canvas.addEventListener('mousedown', this.onDocumentMouseDown, false);
        this.animate();
    }

    setZoomDelta(delta: number) {
        this.zoomDelta += delta
    }

    private initShipComponentsMaterials(textureLoader: TextureLoader) {
        const bump = textureLoader.load("images/Pixel Bump.png");
        const engine = textureLoader.load("images/Engine2.png");
        const fuel = textureLoader.load("images/Fuel.png");
        const lasers = textureLoader.load("images/Lazers.png");
        const radiators = textureLoader.load("images/Radiators.png");
        const engineComponentMaterial = new MeshPhongMaterial({
            map: engine,
            shininess: 100,
            bumpMap: bump,
            specular: 0.5,
            bumpScale: 0.007
        });
        const radiatorComponentMaterial = engineComponentMaterial.clone();
        radiatorComponentMaterial.map = radiators;
        radiatorComponentMaterial.bumpMap = bump;
        const laserComponentMaterial = engineComponentMaterial.clone();
        laserComponentMaterial.map = lasers;
        laserComponentMaterial.bumpMap = bump;
        const fuelComponentMaterial = engineComponentMaterial.clone();
        fuelComponentMaterial.map = fuel;
        fuelComponentMaterial.bumpMap = bump;
        this.shipComponentsMaterials = [
            {component: ShipComponentType.Engine, material: engineComponentMaterial},
            {component: ShipComponentType.Radiator, material: radiatorComponentMaterial},
            {component: ShipComponentType.Laser, material: laserComponentMaterial},
            {component: ShipComponentType.Fuel, material: fuelComponentMaterial}
        ];
    }

    private createLight() {
        const purpleLight = new DirectionalLight(0x7C4D94, 1);
        purpleLight.position.set(-0.8, 1, 0.1);
        purpleLight.target.position.set(0, 0, 0)
        this.scene.add(purpleLight);
        this.scene.add(purpleLight.target);

        const whiteLight = new DirectionalLight(0xffffff, 0.7);
        whiteLight.position.set(1, -1, 0);
        whiteLight.target.position.set(0, 0.1, 0);
        this.scene.add(whiteLight);
        this.scene.add(whiteLight.target);

        this.scene.add(new AmbientLight(0x36394a, 1));
    }

    private bind() {
        this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
        this.onDocumentMouseUp = this.onDocumentMouseUp.bind(this);
        this.onDocumentMouseLeave = this.onDocumentMouseLeave.bind(this);
        this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);
        this.animate = this.animate.bind(this);
    }

    private animate() {
        this.render();
        requestAnimationFrame(this.animate);
    }

    private onDocumentMouseDown(event: MouseEvent) {
        event.preventDefault();
        this.onPointerDownPointerX = event.clientX;
        this.onPointerDownPointerY = event.clientY;
        this.onStartRotateLon = this.cameraRotationLon;
        this.onStartRotateLat = this.cameraRotationLat;
        this.canvas.addEventListener('mousemove', this.onDocumentMouseMove, false);
        this.canvas.addEventListener('mouseup',  this.onDocumentMouseUp, false);
        this.canvas.addEventListener('mouseleave',  this.onDocumentMouseLeave, false);
    }

    private onDocumentMouseMove(event: MouseEvent) {
        this.cameraRotationLonDelta += (this.onPointerDownPointerX - event.clientX) * this.rotationSpeed;
        this.cameraRotationLatDelta += - (this.onPointerDownPointerY - event.clientY) * this.rotationSpeed;
        this.onStartRotateLon += this.cameraRotationLonDelta;
        this.onStartRotateLat += this.cameraRotationLatDelta;
    }

    private onDocumentMouseLeave() {
        this.onDocumentMouseUp();
    }

    private onDocumentMouseUp() {
        this.canvas.removeEventListener('mousemove', this.onDocumentMouseMove, false);
        this.canvas.removeEventListener('mouseup', this.onDocumentMouseUp, false);
        this.canvas.removeEventListener('mouseleave', this.onDocumentMouseLeave, false);
    }

    adjustScale(ships: IShipAndCommands[]) {
    }

    clearSpace(): void {
    }

    drawShipsAndCommands(prevShipsAndCommands: IShipAndCommands[] | undefined,
                         shipsAndCommands: IShipAndCommands[],
                         nextShipsAndCommands: IShipAndCommands[] | undefined,
                         isAutoPlay: boolean,
                         getShootRadius: (power: number, powerDecrease: number) => number) {
        for (let i=0; i<this.mixers.length; i++) {
            this.mixers[i].stopAllAction();
        }
        this.mixers = [];
        this.lastTickTime = +new Date() - this.startTime;
        this.prevShipsAndCommands = prevShipsAndCommands;
        this.shipsAndCommands = shipsAndCommands;
        this.nextShipsAndCommands = nextShipsAndCommands;
        this.isAutoPlay = isAutoPlay;
        this.drawShips(shipsAndCommands);
        this.drawCommands(getShootRadius);
    }

    private drawShips(shipsAndCommands: IShipAndCommands[]) {
        const shipsOnSceneNew: { [id: number]: {mesh: Mesh, sprite: Sprite, components: IShipComponent[]} } = {}
        for (const shipAndCommands of shipsAndCommands) {
            const shipData = shipAndCommands.ship;
            const newShipAndComponents = this.notDrawShips ? undefined : this.drawShip(shipData);
            if (newShipAndComponents !== undefined)
                shipsOnSceneNew[shipData.shipId] = newShipAndComponents;
        }
        for (let i=0; i<this.explosionMeshes.length; i++) {
            this.scene.remove(this.explosionMeshes[i]);
        }
        this.explosionMeshes = [];
        for (const id in this.shipsOnScene) {
            if ((this.shipsOnScene.hasOwnProperty(id) && !shipsOnSceneNew.hasOwnProperty(id))
                || (this.shipsOnScene.hasOwnProperty(id) && shipsOnSceneNew.hasOwnProperty(id)
                    && this.shipsOnScene[id].components.length !== shipsOnSceneNew[id].components.length))
            {
                this.processExplosion(shipsOnSceneNew, +id, shipsAndCommands);
                this.scene.remove(this.shipsOnScene[id].mesh);
            }
        }
        this.shipsOnScene = shipsOnSceneNew;
    }

    // if critical temperature too
    private processExplosion(shipsOnSceneNew: { [p: number]: { mesh: Mesh; components: IShipComponent[] } },
                             id: number, shipsAndCommands: IShipAndCommands[]) {
        const newComponentsCount = shipsOnSceneNew.hasOwnProperty(id) ? shipsOnSceneNew[id].components.length : 0;
        const shipCommandList = shipsAndCommands.filter(s => s.ship.shipId === +id);
        const shipCommands = shipCommandList.length > 0 ? shipCommandList[0].appliedCommands : undefined;
        const nextPosition = shipCommandList.length === 0 ? undefined : this.simulate(shipCommandList[0].ship, 1, true)[0];
        let spentCubes = 0;
        if (shipCommands !== undefined) {
            for (let i = 0; i < shipCommands.length; i++) {
                const command = shipCommands[i];
                if (command.type === ApiCommandType.BurnFuel) {
                    const velocity = (command as IBurnFuelCommand).velocity;
                    spentCubes += Math.abs(velocity.x) + Math.abs(velocity.y);
                } else if (command.type === ApiCommandType.SplitShip) {
                    const matter = (command as ISplitCommand).matter;
                    spentCubes += matter.fuel + matter.lasers + matter.radiators + matter.engines;
                }
            }
        }
        const componentsCountForExplosion = this.shipsOnScene[id].components.length - newComponentsCount - spentCubes;
        const position = shipsOnSceneNew.hasOwnProperty(id) ? shipsOnSceneNew[id].mesh.position : this.shipsOnScene[id].mesh.position;
        if (componentsCountForExplosion > 0) {
            const explosedComponents: IShipComponent[] = [];
            for (let i = newComponentsCount; i < newComponentsCount + componentsCountForExplosion; i++) {
                explosedComponents.push(this.shipsOnScene[id].components[i]);
            }
            this.drawShipExplosion(position, fromGameCoords(nextPosition === undefined ? position : nextPosition), explosedComponents);
        }
    }

    private drawShipExplosion(position: Vector3, nextPosition: Vector3, explosedComponents: IShipComponent[]) {
        const count = explosedComponents.length;
        for (let i = 0; i < count; i++) {
            const component = explosedComponents[i];
            const geometry = new BoxBufferGeometry(1, 1, 1);
            const material = this.shipComponentsMaterials[+component.type].material.clone();
            material.transparent = true;
            const mesh = new Mesh(geometry, material);
            mesh.scale.multiplyScalar(this.shipCubeSize);
            mesh.position.copy(position);
            const positionDelta = Math.log(count);
            const componentPosition = component.position.clone().multiplyScalar(this.shipCubeSize / 2);
            const randomPosVec = new Vector3(componentPosition.x * (1 + Math.random() * positionDelta), componentPosition.y * (1 + Math.random() * positionDelta), (Math.random() - 0.5) * positionDelta);
            const startPos = position.clone().add(componentPosition);
            const finalPos = nextPosition.clone().add(randomPosVec);
            const positionKF = new VectorKeyframeTrack('.position', [0, 1],
                [startPos.x, startPos.y, startPos.z, finalPos.x, finalPos.y, finalPos.z]);
            const scaleKF = new VectorKeyframeTrack('.scale', [0, 1], [this.shipCubeSize, this.shipCubeSize, this.shipCubeSize, this.shipCubeSize * 0.2, this.shipCubeSize * 0.2, this.shipCubeSize * 0.2]);
            const opacityKF = new NumberKeyframeTrack( '.material.opacity', [0, 1], [1, 0]);
            const colorKF = new ColorKeyframeTrack( '.material.color', [ 0, 0.5, 1 ], [ 1, 1, 1, 1, 0, 0, 1, 0, 0], InterpolateLinear );
            const clip = new AnimationClip('Action', 1, [scaleKF, positionKF, opacityKF, colorKF]);
            const mixer = new AnimationMixer(mesh);
            const clipAction = mixer.clipAction(clip);
            this.explosionMeshes.push(mesh);
            this.mixers.push(mixer);
            clipAction.play();
            this.scene.add(mesh);
        }
    }

    private drawShip(shipData: IShip): {mesh: Mesh, sprite: Sprite, components: IShipComponent[]} | undefined {
        let ship: Mesh | undefined;
        const componentsCount = GetShipComponentsCount(shipData);
        let components;
        let sprite;
        if (this.shipsOnScene.hasOwnProperty(shipData.shipId)
            && this.shipsOnScene[shipData.shipId].components.length === componentsCount)
        {
            const s = this.shipsOnScene[shipData.shipId];
            ship = s.mesh;
            components = s.components;
            sprite = s.sprite;
        } else {
            const result = this.createShip(shipData);
            ship = result?.mesh;
            components = result?.components;
            sprite = result?.sprite;
            if (ship === undefined)
                return undefined;
            this.scene.add(ship);
        }
        if (ship === undefined || components === undefined || sprite === undefined)
            return undefined;
        ship.position.copy(fromGameCoords(shipData.position));
        ship.lookAt(ship.position.clone().add(fromGameCoords(shipData.velocity)));
        return {mesh: ship, sprite: sprite, components: components};
    }

    private createShip(shipData: IShip): { mesh: Mesh, sprite: Sprite, components: IShipComponent[] } | undefined {
        const components = GetShipComponents(shipData);
        const byTypes : BoxBufferGeometry[][] = [[],[],[],[]];
        const dummy = new Object3D();
        let ship : Mesh | undefined = undefined;
        for (let i=0; i<components.length; i++) {
            const component = components[i];
            const geometry = new BoxBufferGeometry(1 + 0.001 * i, 1 + 0.001 * i, 1 + 0.001 * i);
            const offset = 0.7;
            dummy.position.set(component.position.x * offset, component.position.y * offset, component.position.z * offset);
            dummy.updateMatrix();
            geometry.applyMatrix4(dummy.matrix);
            if (i === 0) {
                ship = new Mesh(geometry, this.shipComponentsMaterials[+component.type].material);
            } else {
                byTypes[+component.type].push(geometry);
            }
        }
        if (ship === undefined)
            return undefined;
        for (let i=0; i<4; i++) {
            const geometries = byTypes[i];
            if (geometries.length === 0)
                continue;
            const mergedGeometry = geometries.length === 1
                ? geometries[0]
                : BufferGeometryUtils.mergeBufferGeometries(geometries);
            const mesh = new Mesh(mergedGeometry, this.shipComponentsMaterials[i].material);
            ship.add(mesh);
        }
        ship.scale.multiplyScalar(this.shipCubeSize);
        const material = this.shipSpriteMaterial.clone();
        material.color.set(this.shipColor(shipData));
        const sprite = new Sprite(material);
        ship.add(sprite);
        sprite.scale.multiplyScalar(0.013);
        return { mesh: ship, sprite: sprite, components: components };
    }

    private drawCommands(getShootRadius: (power: number, powerDecrease: number) => number): void {
        this.removeOldObjectsFromScene();

        for (let sap of this.shipsAndCommands) {
            for (let cmd of sap.appliedCommands) {
                if (cmd.type === ApiCommandType.Detonate)
                    this.drawDetonation(sap.ship, cmd);
                else if (cmd.type === ApiCommandType.Shoot)
                    this.drawLaser(sap.ship, cmd, getShootRadius);
            }
        }
        if (this.nextShipsAndCommands !== undefined) {
            for (let sap of this.nextShipsAndCommands) {
                if (this.shipsOnScene[sap.ship.shipId] === undefined)
                    continue;
                for (let cmd of sap.appliedCommands) {
                    if (cmd.type === ApiCommandType.BurnFuel) {
                        this.drawExhaust(sap.ship, cmd);
                    }
                }
            }
        }
    }

    private removeOldObjectsFromScene() {
        for (let detonation of this.detonationsOnScene)
            this.scene.remove(detonation.outerSphere);
        this.detonationsOnScene = [];
        for (let laserLine of this.laserLinesOnScene)
            this.scene.remove(laserLine.laser);
        this.laserLinesOnScene = [];
        for (let shootRadius of this.laserShootRadiiOnScene)
            this.scene.remove(shootRadius.shoot);
        this.laserShootRadiiOnScene = [];
        for (let exhaust of this.exhaustsOnScene)
            this.scene.remove(exhaust.mesh);
    }

    private drawLaser(ship: IShip, laserCommand: ILaserCommand, getShootRadius: (power: number, powerDecrease: number) => number) {
        const target = laserCommand.target;
        const damage = laserCommand.damage;
        const damageDecreaseFactor = laserCommand.damageDecreaseFactor;

        const laserMaterial = new MeshBasicMaterial({color: new Color(0.8, 0.8, 0.8), transparent: true});
        const from = fromGameCoords(ship.position);
        const to = fromGameCoords(target);
        const geometry = new CylinderGeometry(0.1, 0.1, from.distanceTo(to), 8);
        const laser = new Mesh(geometry, laserMaterial);

        const glowMaterial = new ShaderMaterial({
            uniforms: {
                "c":   { type: "f", value: 0.5 } as any,
                "p":   { type: "f", value: 4.7 } as any,
                glowColor: { type: "c", value: new Color(1, 0, 0) } as any,
                viewVector: { type: "v3", value: this.camera.position } as any
            },
            vertexShader: vertexShaderGlow,
            fragmentShader: fragmentShaderGlow,
            side: FrontSide,
            blending: AdditiveBlending,
            transparent: true
        });

        let glowGeom = new CylinderGeometry(0.2, 0.2, from.distanceTo(to), 8);
        let glowMesh = new Mesh(glowGeom, glowMaterial);

        this.laserLinesOnScene.push({laser: laser, glow: glowMesh, target: to, shipId: ship.shipId});
        laser.add(glowMesh);
        this.scene.add(laser);

        {
            const opacityKF = new NumberKeyframeTrack('.material.opacity', [0, 1 / 3, 1], [1, 0, 0]);
            const scaleKF = new VectorKeyframeTrack('.scale', [0, 1 / 3, 1], [1, 1, 1, 0, 1, 0, 0, 1, 0]);
            const clip = new AnimationClip('Action', 1, [opacityKF, scaleKF]);
            const mixer = new AnimationMixer(laser);
            const clipAction = mixer.clipAction(clip);
            this.mixers.push(mixer);
            clipAction.play();
        }

        const shootRadiusMaterial = new MeshBasicMaterial({ color: new Color(1, 0.9, 0.9), opacity:0.1, transparent: true, side: FrontSide });
        const shootRadiusGeometry = new SphereGeometry(getShootRadius(damage, damageDecreaseFactor) * 1.4 /*sqrt(2)*/, 30, 30);
        const shootRadius = new Mesh(shootRadiusGeometry, shootRadiusMaterial);
        shootRadius.position.copy(fromGameCoords(laserCommand.target));

        const innerSphere = new Mesh(shootRadiusGeometry.clone(), shootRadiusMaterial.clone());
        innerSphere.material.side = BackSide;
        shootRadius.add(innerSphere);

        this.laserShootRadiiOnScene.push({shoot: shootRadius, innerSphere: innerSphere, glow: new Mesh()});

        {
            const scaleKF = new VectorKeyframeTrack('.scale', [0, 0.3, 0], [0.2, 0.2, 0.2, 0.9, 0.9, 0.9, 1, 1, 1]);
            const opacityKF = new NumberKeyframeTrack('.material.opacity', [0, 0.1, 0.5, 0.8, 1], [0.01, 0.1, 0.05, 0, 0]);
            const clip = new AnimationClip('Action', 1, [scaleKF, opacityKF]);
            const mixer = new AnimationMixer(shootRadius);
            const clipAction = mixer.clipAction(clip);
            this.mixers.push(mixer);
            clipAction.play();
            const mixerInner = new AnimationMixer(innerSphere);
            const clipActionInner = mixerInner.clipAction(clip);
            this.mixers.push(mixerInner);
            clipActionInner.play();
        }

        this.scene.add(shootRadius);
    }

    private drawDetonation(ship: IShip, detonateCommand: IDetonateCommand) {
        const power = detonateCommand.power;
        const powerDecrease = detonateCommand.powerDecreaseStep;
        const r = power / powerDecrease - 1;
        const material = new MeshBasicMaterial({ color: new Color(1, 0.9, 0.9), opacity:0.1, transparent: true, side: FrontSide });
        const geometry = new SphereGeometry(r * 1.4, 30, 30);
        const detonation = new Mesh(geometry, material);
        detonation.position.copy(fromGameCoords(ship.position));

        const innerSphere = new Mesh(geometry.clone(), material.clone());
        innerSphere.material.side = BackSide;
        detonation.add(innerSphere);

        this.detonationsOnScene.push({ outerSphere: detonation, innerSphere: innerSphere });

        {
            const scaleKF = new VectorKeyframeTrack('.scale', [0, 0.3, 0], [0.2, 0.2, 0.2, 0.9, 0.9, 0.9, 1, 1, 1]);
            const opacityKF = new NumberKeyframeTrack('.material.opacity', [0, 0.1, 0.5, 0.8, 1], [0.05, 0.2, 0.1, 0, 0]);
            const clip = new AnimationClip('Action', 1, [scaleKF, opacityKF]);
            const mixer = new AnimationMixer(detonation);
            const clipAction = mixer.clipAction(clip);
            this.mixers.push(mixer);
            clipAction.play();
            const mixerInner = new AnimationMixer(innerSphere);
            const clipActionInner = mixerInner.clipAction(clip);
            this.mixers.push(mixerInner);
            clipActionInner.play();
        }

        this.scene.add(detonation);
    }

    private drawExhaust(ship: IShip, burnFuelCommand: IBurnFuelCommand) {
        const shipOnScene = this.shipsOnScene[ship.shipId];
        if (shipOnScene === undefined)
            return;
        const velocity = fromGameCoords(burnFuelCommand.velocity);
        const sizeCoefficient = this.shipCubeSize;
        const heightCoefficient = (Math.abs(velocity.x) > 1 || Math.abs(velocity.y) > 1 ? 2 : 1);
        const glowMaterial = new ShaderMaterial({
            uniforms: {
                "c":   { type: "f", value: 0.3 } as any,
                "p":   { type: "f", value: 4 } as any,
                glowColor: { type: "c", value: new Color(0, 0, 0) } as any,
                viewVector: { type: "v3", value: this.camera.position } as any
            },
            vertexShader: vertexShaderGlow,
            fragmentShader: fragmentShaderGlow,
            side: BackSide,
            blending: AdditiveBlending,
            transparent: true
        });

        const geometry = new ConeGeometry(sizeCoefficient, sizeCoefficient * 3 * heightCoefficient, 30, 1, true);
        geometry.translate(0, sizeCoefficient * (3 + heightCoefficient - 1), 0);
        if (velocity.x === 0 && velocity.y < 0)
            geometry.rotateZ(MathUtils.degToRad(180));
        else
            geometry.rotateZ(-1 * Math.sign(velocity.x) * new Vector3(0,1, 0).angleTo(velocity.normalize()));
        const glow = new Mesh(geometry, glowMaterial);
        const mesh = new Mesh(geometry.clone(), new MeshBasicMaterial({ color: 0x999999, opacity: 0, transparent: true }));
        mesh.add(glow);

        mesh.position.copy(shipOnScene.mesh.position);
        this.scene.add(mesh);
        this.exhaustsOnScene.push({mesh: mesh, glow: glow, shipId: ship.shipId});
    }

    drawPlanet(radius: number): void {
        if (this.planet !== undefined)
            return;
        const material = new MeshPhongMaterial({color: new Color(0.5, 0.5, 0.5), transparent: true, alphaTest: 0.5, side: DoubleSide, alphaMap: this.planetOpacityTex});
        const geometry = new BoxGeometry(1, 1, 1, 2, 2, 2);
        this.planet = new Mesh(geometry, material);
        let size = 2 * radius + 1;
        this.planet.scale.set(size, size, size);
        this.planet.position.set(0, 0, 0);
        const innerPlanetMaterial = new MeshBasicMaterial({ color: new Color(0.02, 0.02, 0.03), opacity: 0.5, transparent: true, side: DoubleSide });
        const innerPlanet = new Mesh(geometry.clone().scale(0.999, 0.999, 0.999), innerPlanetMaterial);
        this.planet.add(innerPlanet);

        const glowMaterial = new ShaderMaterial({
            uniforms: {
                "c":   { type: "f", value: 0.9 } as any,
                "p":   { type: "f", value: 5.6 } as any,
                glowColor: { type: "c", value: new Color(0xbbbbbb) } as any,
                viewVector: { type: "v3", value: this.camera.position } as any
            },
            vertexShader: vertexShaderGlow,
            fragmentShader: fragmentShaderGlow,
            side: FrontSide,
            blending: AdditiveBlending,
            transparent: true
        });

        let glowCubeGeom = this.planet.geometry.clone();

        let glowMesh = new Mesh(glowCubeGeom, glowMaterial);
        glowMesh.scale.multiplyScalar(0.99);
        this.planet.add(glowMesh);
        this.planetGlow = glowMesh;
        this.scene.add(this.planet);
    }

    drawSafePlace(radius: number): void {
    }

    drawTrajectories(ships: IShipAndCommands[], showTrajectories: boolean): void {
    }

    private updateShipsIntermediatePositions() {
        if (this.nextShipsAndCommands === undefined || !this.isAutoPlay)
            return;
        const timeFromTick = +new Date() - this.startTime - this.lastTickTime;
        const fraction = Math.min(timeFromTick / this.timePerTick, 1);
        for (let i = 0; i<this.shipsAndCommands.length; i++) {
            const shipData = this.shipsAndCommands[i].ship;
            const shipId = shipData.shipId;
            const nextShipDataList = this.nextShipsAndCommands.filter(s => s.ship.shipId === shipId);
            if (nextShipDataList.length === 0)
                continue;
            const nextShipData = nextShipDataList[0].ship;
            const ship = this.shipsOnScene[shipId]?.mesh;
            if (ship === undefined)
                continue;
            const gamePos = {
                x: shipData.position.x * (1 - fraction) + nextShipData.position.x * fraction,
                y: shipData.position.y * (1 - fraction) + nextShipData.position.y * fraction
            }
            ship.position.copy(fromGameCoords(gamePos));
            const gameLookAt = {
                x: shipData.velocity.x * (1 - fraction) + nextShipData.velocity.x * fraction,
                y: shipData.velocity.y * (1 - fraction) + nextShipData.velocity.y * fraction
            };
            ship.lookAt(ship.position.clone().add(fromGameCoords(gameLookAt)));
        }
    }

    private updateLaserPositions() {
        for (let laserData of this.laserLinesOnScene) {
            const laser = laserData.laser;
            const ship = this.shipsOnScene[laserData.shipId];
            if (ship === undefined)
                continue;
            const from = ship.mesh.position;
            const to = laserData.target;
            laser.scale.setY(from.distanceTo(to) / (laser.geometry as CylinderGeometry).parameters.height);
            laser.position.copy(from.clone().add(to).multiplyScalar(0.5));
            laser.lookAt(to);
            laser.rotateOnAxis(new Vector3(1, 0, 0), MathUtils.degToRad(90));
        }
    }

    setNextShipInView(previous: boolean | undefined = undefined) {
        this.isGeneralView = false;
        const shipsKeys = this.shipsOnScene === undefined ? undefined : Object.keys(this.shipsOnScene);
        if (shipsKeys === undefined || shipsKeys.length === 0)
            return;
        if (this.shipIdInView === undefined) {
            this.shipIdInView = +shipsKeys[0];
            this.updateShipInViewFlyClockwise();
            return;
        }
        let nextShipId = !previous
            // @ts-ignore
            ? shipsKeys.find(k => +k > this.shipIdInView)
            // @ts-ignore
            : shipsKeys.reverse().find(k => +k < this.shipIdInView);
        if (nextShipId === undefined) {
            if (!previous)
                this.shipIdInView = +shipsKeys[0];
            else
                this.shipIdInView = +shipsKeys[shipsKeys.length - 1];
        } else
            this.shipIdInView = +nextShipId;
        this.updateShipInViewFlyClockwise();
    }

    private updateShipInViewFlyClockwise() {
        if (this.shipIdInView === undefined)
            return;
        let shipDataList = this.shipsAndCommands.filter(s => s.ship.shipId === this.shipIdInView);
        if (shipDataList.length === 0)
            return;
        let shipData = shipDataList[0];
        let position = fromGameCoords(shipData.ship.position);
        let velocity = fromGameCoords(shipData.ship.velocity);
        if (velocity.x === 0 && velocity.y === 0 && this.nextShipsAndCommands !== undefined) {
            shipDataList = this.nextShipsAndCommands.filter(s => s.ship.shipId === this.shipIdInView);
            shipData = shipDataList[0];
            position = fromGameCoords(shipData.ship.position);
            velocity = fromGameCoords(shipData.ship.velocity);
        }
        this.shipInViewFlyClockwise = (position.x < 0 && velocity.y > 0) || (position.x > 0 && velocity.y < 0)
            || (velocity.y === 0 && ((position.y < 0 && velocity.x < 0) || (position.y > 0 && velocity.x > 0)));
    }

    setPreviousShipInView() {
        this.setNextShipInView(true);
    }

    switchGeneralView() {
        this.isGeneralView = !this.isGeneralView;
        if (!this.isGeneralView && this.shipIdInView === undefined) {
            this.shipIdInView = +Object.keys(this.shipsOnScene)[0];
            this.updateShipInViewFlyClockwise();
        }
        this.cameraRotationLon = 0;
        this.cameraRotationLat = 0;
    }

    private setCameraNotRotated() {
        if (this.isGeneralView) {
            this.cameraPosition = new Vector3(25, -75, 35);
            this.lookAt = new Vector3();
        }
        else {
            const shipInView = this.shipIdInView === undefined ? undefined : this.shipsOnScene[this.shipIdInView];
            if (shipInView === undefined) {
                return;
            }
            const normal = shipInView.mesh.position.clone().cross(new Vector3(0, 0, 1)).normalize();
            const sign = this.shipInViewFlyClockwise ? -1 : 1;
            this.cameraPosition = shipInView.mesh.position.clone()
                .add(normal.multiplyScalar(sign * 5))
                .add(shipInView.mesh.position.clone().normalize().multiplyScalar(3));
            this.lookAt = shipInView.mesh.position.clone();
        }
    }

    private updateCamera() {
        this.setCameraNotRotated();

        this.cameraRotationLat += this.cameraRotationLatDelta * this.dampingFactor;
        this.cameraRotationLon += this.cameraRotationLonDelta * this.dampingFactor;
        this.cameraRotationLat = Math.max(-89, Math.min(89, this.cameraRotationLat));
        this.cameraRotationLonDelta *= (1 - this.dampingFactor);
        this.cameraRotationLatDelta *= (1 - this.dampingFactor);

        const zoomFactor = 1.0 - this.zoomDelta * this.zoomDampingFactor;
        this.zoom = Math.min(Math.max(this.zoom * zoomFactor, this.minZoom), this.maxZoom);
        this.zoomDelta += - this.zoomDelta * this.zoomDampingFactor;

        const cameraToLookAt = this.cameraPosition.clone().sub(this.lookAt);
        const cameraDistance = cameraToLookAt.length() * this.zoom;
        const notRotatedLat = MathUtils.radToDeg(Math.asin(cameraToLookAt.z / cameraDistance));
        const notRotatedLon = MathUtils.radToDeg(Math.atan2(cameraToLookAt.y, cameraToLookAt.x));

        const cameraLat = Math.max(-89, Math.min(89, this.cameraRotationLat + notRotatedLat));
        const cameraLon = this.cameraRotationLon + notRotatedLon;

        const latRad = MathUtils.degToRad(cameraLat);
        const lonRad = MathUtils.degToRad(cameraLon);
        this.camera.position.x = this.lookAt.x + cameraDistance * Math.cos(latRad) * Math.cos(lonRad);
        this.camera.position.y = this.lookAt.y + cameraDistance * Math.cos(latRad) * Math.sin(lonRad);
        this.camera.position.z = this.lookAt.z + cameraDistance * Math.sin(latRad);
        this.camera.up = new Vector3(0, 0, 1);
        this.camera.lookAt(this.lookAt);

        this.camera.updateProjectionMatrix();
    }

    private updateRenderer() {
        let rendererSize: Vector2 = new Vector2();
        this.renderer.getSize(rendererSize);
        const isFullScreen = document.fullscreenElement !== null;
        const width = (!isFullScreen ? this.canvas.width : window.screen.width);
        const height = (!isFullScreen ? this.canvas.height : window.screen.height);
        if (rendererSize.width !== width || rendererSize.height !== height) {
            this.renderer.setSize(width, height);
            this.bloomComposer.setSize(width, height);
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
            this.fxaaPass.material.uniforms['resolution'].value.x = 1 / (width);
            this.fxaaPass.material.uniforms['resolution'].value.y = 1 / (height);
        }
    }

    private updateGlows() {
        const timeFromTick = +new Date() - this.startTime - this.lastTickTime;
        const fraction = Math.min(timeFromTick / this.timePerTick, 1);
        if (this.planetGlow !== undefined) {
            this.updateGlow(this.planetGlow);
        }
        for (const laserData of this.laserLinesOnScene) {
            const glow = laserData.glow;
            this.updateGlow(glow);
            if (this.isAutoPlay) {
                const uniforms = (glow.material as ShaderMaterial).uniforms;
                uniforms.c = {type: "f", value: 0.5 * Math.max(1 - fraction * 3, 0)} as any;
            }
        }
    }

    private updateGlow(glow: Mesh) {
        let worldPosition = new Vector3();
        worldPosition = glow.getWorldPosition(worldPosition);
        (glow.material as ShaderMaterial).uniforms.viewVector.value
            = new Vector3().subVectors(this.camera.position, worldPosition);
    }

    private updateExhausts() {
        const timeFromTick = +new Date() - this.startTime - this.lastTickTime;
        const fraction = Math.min(timeFromTick / this.timePerTick, 1);
        for (const exhaust of this.exhaustsOnScene) {
            const glow = exhaust.glow;
            this.updateGlow(glow);
            if (this.isAutoPlay) {
                const uniforms = (glow.material as ShaderMaterial).uniforms;
                const delay = 0.6;
                const coef = fraction < delay ? 0 : Math.max(1 - (fraction - delay) / (1 - delay), 0);
                uniforms.glowColor = { type: "c", value: new Color(0.6 * coef, 0.5 * coef, 0.5 * coef) } as any;
                (exhaust.mesh.material as MeshBasicMaterial).opacity = 0.25 * coef;
                const ship = this.shipsOnScene[exhaust.shipId];
                if (ship === undefined)
                    continue;
                exhaust.mesh.position.copy(ship.mesh.position);
            }
        }
    }

    private updateExplosions() {
        if (this.isAutoPlay) {
            const timeFromTick = +new Date() - this.startTime - this.lastTickTime;
            const fraction = Math.min(timeFromTick / this.timePerTick, 1);
            for (const mixer of this.mixers) {
                mixer.setTime(fraction);
            }
        }
    }

    private fixShootRadiiSphereOpacity() {
        for (const shoot of this.laserShootRadiiOnScene) {
            const outerSphere = shoot.shoot;
            const innerSphere = shoot.innerSphere;
            innerSphere.position.copy(outerSphere.position.clone().sub(this.camera.position).normalize().multiplyScalar(0.01));
        }
        for (const detonation of this.detonationsOnScene) {
            const outerSphere = detonation.outerSphere;
            const innerSphere = detonation.innerSphere;
            innerSphere.position.copy(outerSphere.position.clone().sub(this.camera.position).normalize().multiplyScalar(0.01));
        }
    }

    private render() {
        this.updateShipsIntermediatePositions();
        this.updateLaserPositions();
        this.updateGlows();
        this.updateExplosions();
        this.updateExhausts();
        if (+new Date() - this.startTime - this.lastTickTime > this.timePerTick) {
            for (let i=0; i<this.mixers.length; i++) {
                this.mixers[i].stopAllAction();
            }
            this.mixers = [];
        }
        this.updateCamera();
        this.fixShootRadiiSphereOpacity();
        this.updateRenderer();
        this.bloomComposer.render();
    }

    private drawStarsSphere() {
        const material = new MeshBasicMaterial({map: this.skyTex, side: BackSide});
        const geometry = new SphereGeometry(2000, 24, 24);
        const sphere = new Mesh(geometry, material);
        sphere.lookAt(0, 1, 0);
        this.scene.add(sphere);
    }

    setTimePerTick(timePerTick: number): void {
        this.timePerTick = timePerTick;
    }

    shipColor(ship: IShip) {
        return ship.role === ApiPlayerRole.Defender ? 0x40FFFD : 0xFFC573;
    }
}

function fromGameCoords(coords: V) : Vector3 {
    return new Vector3(coords.x, -coords.y, 0);
}

const vertexShaderGlow = `
uniform vec3 viewVector;
uniform float c;
uniform float p;
varying float intensity;
void main()
{
    vec3 vNormal = normalize( normalMatrix * normal );
    vec3 vNormel = normalize( normalMatrix * viewVector );
    intensity = pow( c - dot(vNormal, vNormel), p );

    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}`;
const fragmentShaderGlow = `
uniform vec3 glowColor;
varying float intensity;
void main()
{
    vec3 glow = glowColor * intensity;
    gl_FragColor = vec4( glow, 1.0 );
}`;