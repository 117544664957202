import React from 'react';
import styles from './styles.module.css';
import IBlogPost from '../../models/IBlogPost';
import BlogPost from '../BlogPost';

interface BlogListProps {
    posts: IBlogPost[];
}

export default function BlogList(props: BlogListProps) {
    return (
        <main className={styles.root}>
            { props.posts.map(item =>
                <div className={ styles.listItem } key={ item.id }>
                    <BlogPost { ...item } isInList/>
                </div>
            ) }
        </main>
    )
}
