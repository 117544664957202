import React from 'react';
import astronomerPosts from '../../astronomerPosts.json';
import orgsPosts from '../../orgsPosts.json';
import BlogPost from '../BlogPost';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';

type BlogPostPageProps = {
    match: {
        params: {
            postId: string;
        }
    }
}

export default function BlogPostPage(props: BlogPostPageProps) {
    const { postId } = props.match.params;
    const astronomerPostIndex = astronomerPosts.findIndex(elem => elem.id === parseInt(postId, 10));
    const orgsPostIndex = orgsPosts.findIndex(elem => elem.id === parseInt(postId, 10));
    
    if (astronomerPostIndex === -1 && orgsPostIndex === -1) {
        return (
            <p>404: Post Not Found</p>
        )
    }
    
    const postIndex = astronomerPostIndex !== -1 ? astronomerPostIndex : orgsPostIndex;
    const posts = astronomerPostIndex !== -1 ? astronomerPosts : orgsPosts;

    const currentPost = posts[postIndex];
    const nextPost = postIndex > 0 && posts[postIndex - 1];
    const prevPost = postIndex < posts.length - 1 && posts[postIndex + 1];

    return (
        <div className={ styles.root }>
            <BlogPost { ...currentPost } isInList={ false }/>
            <footer className={ styles.footer + ' container' }>
                <div className={ styles.footerItem }>
                    { prevPost &&
                    <>Prev: <Link to={ `${ ROUTES.post }/${ prevPost.id }` }>{ prevPost.title }</Link></>
                    }
                </div>
                <div className={ styles.footerItem }>
                    { nextPost &&
                    <>Next: <Link to={ `${ ROUTES.post }/${ nextPost.id }` }>{ nextPost.title }</Link></>
                    }
                </div>
            </footer>
        </div>
    )
}


