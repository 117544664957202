import { connect } from 'react-redux';
import SubmissionsPage from '../components/SubmissionsPage';
import IStore from '../models/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { activateSubmission, loadSubmissions, updateLastSeenSubmission } from '../actions/submissions';
import createEmptyAction from '../utils/createEmptyAction';

const mapState = (store: IStore) => {
    return {
        team: store.team.team,
        submissions: store.submissions.submissions,
    }
}

const mapDispatch = (dispatch: ThunkDispatch<IStore, unknown, AnyAction>) => {
    return {
        loadSubmissions: () => dispatch(loadSubmissions()),
        updateLastSeenSubmission: (id: string) => dispatch(updateLastSeenSubmission(createEmptyAction(id))),
        activateSubmission: (id: string) => dispatch(activateSubmission(createEmptyAction(id))),
    }
}


export default connect(mapState, mapDispatch)(SubmissionsPage);
