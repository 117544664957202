import convertGameLog from './GameLogConverter';
import Renderer2d from './Renderer2d';
import Renderer3d from './Renderer3d';
import Renderer2dAnd3d from './Renderer2dAnd3d';
import {
    ApiPlayerRole,
    ILogData,
    IShip,
    ITick,
} from "./GameLogModels";
import IRenderer from "./IRenderer";

export default function run(logData: ILogData, canvasDelta: number, done?: () => void) {
    const canvas2dElement = document.getElementById("field2d");
    const canvas3dElement = document.getElementById("field3d");
    const consoleElement = document.getElementById("console");

    if (!canvas2dElement || !canvas3dElement || !consoleElement) return;

    const timePerTick2dDefault = 100;
    const timePerTick3dDefault = 1000;

    let is2d = true;
    let timePerTick = timePerTick2dDefault;

    const canvas2d = canvas2dElement as HTMLCanvasElement;
    const maybeCtx2d = canvas2d.getContext('2d');
    if (!maybeCtx2d) return;
    const ctx2d = maybeCtx2d as CanvasRenderingContext2D;
    const renderer2d = new Renderer2d(ctx2d, canvas2d);

    const canvas3d = canvas3dElement as HTMLCanvasElement;
    const maybeCtx3d = canvas3d.getContext('webgl');
    if (!maybeCtx3d) return;
    const ctx3d = maybeCtx3d as WebGLRenderingContext;
    const renderer3d = new Renderer3d(ctx3d, canvas3d, timePerTick, simulate);
    canvas3d.hidden = true;

    const renderer = new Renderer2dAnd3d(renderer2d, renderer3d) as IRenderer;

    let index = 0;
    let log = convertGameLog(logData);
    let factualTicksLength = log.gameLog?.ticks.length === undefined ? 0 : log.gameLog.ticks.length;
    addLastTicks();
    let showTrajectories = true;
    let autoPlayTimerId: number = 0;
    let isAutoPlay = false;
    let lastMouseDownTime : number | null = null;

    const wheelSpeed = 0.0005;

    const resizeListener = () => {
        resize();
        update();
    };
    window.addEventListener('resize', resizeListener);

    document.body.addEventListener('keydown', keydown);
    canvas2d.addEventListener('mousedown', onMouseDown);
    canvas3d.addEventListener('mousedown', onMouseDown);
    canvas2d.addEventListener('mouseup', onMouseUp);
    canvas3d.addEventListener('mouseup', onMouseUp);
    canvas3d.addEventListener('wheel', onWheel);

    resize();
    update();
    startAutoplay();

    function addLastTicks() {
        if (!log.gameLog)
            return;
        const count = 5;
        const lastTick = log.gameLog.ticks[factualTicksLength - 1];
        for (let i=0; i<count; i++)
            log.gameLog.ticks.push({tick: factualTicksLength + i, ships: []});
        for (const shipAndCommands of lastTick.ships) {
            const result = simulate(shipAndCommands.ship, count);
            for (let i=0; i<count; i++) {
                const sac = {ship: {...shipAndCommands.ship, position: result[i]}, appliedCommands: []};
                log.gameLog.ticks[factualTicksLength + i].ships.push(sac);
            }
        }
    }

    function resize() {
        canvas2d.width = window.innerWidth - 64;
        canvas2d.height = window.innerHeight - 260 + canvasDelta;
        canvas3d.width = window.innerWidth - 64;
        canvas3d.height = window.innerHeight - 260 + canvasDelta;
    }

    function onWheel(ev: WheelEvent) {
        if (!log.gameLog || is2d) return;
        if (ev.deltaY > 0) {
            renderer3d.setZoomDelta(ev.deltaY * wheelSpeed);
        } else {
            renderer3d.setZoomDelta(-1 + 1 / (1 - ev.deltaY * wheelSpeed));
        }
        ev.preventDefault();
        return false;
    }

    function keydown(ev: KeyboardEvent) {
        if (!log.gameLog) return;
        const code = ev.code;
        if (code === "ArrowRight" || code === "KeyD") {
            rewind(1, factualTicksLength);
            stopAutoplay();
        } else if (code === "ArrowLeft" || code === "KeyA") {
            rewind(-1, factualTicksLength);
            stopAutoplay();
        } else if (code === "Home" || code === "KeyQ") {
            index = 0;
            stopAutoplay();
            if (!is2d) {
                renderer3d.isGeneralView = true;
                renderer3d.cameraRotationLon = 0;
                renderer3d.cameraRotationLat = 0;
            }
        } else if (code === "End" || code === "KeyE") {
            index = factualTicksLength - 1;
            stopAutoplay();
        } else if (code === "Equal") {
            if (is2d)
                renderer2d.cellSize *= 1.2;
            else {
                renderer3d.setZoomDelta(-0.02);
                return;
            }
        } else if (code === "Minus") {
            if (is2d) {
                if (renderer2d.cellSize > 0.4)
                    renderer2d.cellSize /= 1.2;
            }
            else {
                renderer3d.setZoomDelta(0.02);
                return;
            }
        }
        else if (code === "Comma") {
            timePerTick = Math.min(1000, timePerTick + 10);
            stopAutoplay();
            startAutoplay();
        } else if (code === "Period") {
            timePerTick = Math.max(20, timePerTick - 10);
            stopAutoplay();
            startAutoplay();
        } else if (code === "KeyP" || code === "Space") {
            if (autoPlayTimerId)
                stopAutoplay();
            else
                startAutoplay();
        } else if (code === "Digit1") {
            const instruction = document.getElementsByClassName("instruction")[0];
            canvas3d.hidden = !is2d;
            canvas2d.hidden = is2d;
            if (is2d) {
                timePerTick = timePerTick3dDefault;
                instruction.classList.add("only3d");
                renderer3d.notDrawShips = false;
            } else {
                timePerTick = timePerTick2dDefault;
                instruction.classList.remove("only3d");
            }
            is2d = !is2d;
            renderer.setTimePerTick(timePerTick);
            if (isAutoPlay) {
                stopAutoplay();
                startAutoplay();
            }
        } else if (code === "KeyW" || code === "KeyS" || code === "KeyZ") {
            if (is2d)
                return;
            if (code === "KeyZ")
                renderer3d.switchGeneralView();
            else if (code === "KeyW")
                renderer3d.setNextShipInView();
            else
                renderer3d.setPreviousShipInView();
        } else if (code === "Enter" && !is2d) {
            const c = canvas3d as any;
            if (document.fullscreenElement === null) {
                if (canvas3d.requestFullscreen)
                    canvas3d.requestFullscreen();
                else if (c.webkitRequestFullscreen)
                    c.webkitRequestFullscreen();
                else if (c.mozRequestFullscreen)
                    c.mozRequestFullscreen();
            }
        }
        else
            return;
        ev.preventDefault();
        update();
    }

    function stopAutoplay() {
        clearTimeout(autoPlayTimerId);
        isAutoPlay = false;
        autoPlayTimerId = 0;
    }

    function startAutoplay() {
        isAutoPlay = true;
        autoPlayTimerId = setInterval(autoplayTick, timePerTick) as any as number;
    }

    function autoplayTick() {
        if (!log.gameLog)
            return;
        rewind(1, log.gameLog.ticks.length);
        update();
        if (index >= (is2d ? factualTicksLength : log.gameLog.ticks.length - 1)) {
            stopAutoplay();
            if (done) {
                window.removeEventListener('resize', resizeListener);
                document.body.removeEventListener('keydown', keydown);
                canvas2d.removeEventListener('click', click);
                canvas3d.removeEventListener('click', click);
                done();
            }
        }
    }

    function click() {
        showTrajectories = !showTrajectories;
        update();
    }

    function onMouseDown() {
        lastMouseDownTime = Date.now();
    }

    function onMouseUp() {
        if (lastMouseDownTime == null || Date.now() - lastMouseDownTime > 200) // not move
            return;
        lastMouseDownTime = null;
        click();
    }

    function rewind(dir: 1 | -1, ticksCount: number) {
        if (!log.gameLog) return
        index = Math.min(ticksCount - 1, Math.max(0, index + dir));
    }

    function update() {
        if (!log.gameLog) return;
        const hasNextTick = index < log.gameLog.ticks.length - 1;
        const hasPrevTick = index > 0;
        drawEvent(hasPrevTick ? log.gameLog.ticks[index - 1] : undefined,
            log.gameLog.ticks[index],
            hasNextTick ? log.gameLog.ticks[index + 1] : undefined, isAutoPlay);
    }

    function drawEvent(prevTick: ITick | undefined, tick: ITick, nextTick: ITick | undefined, isAutoPlay: boolean) {
        const ships = tick.ships;

        renderer.adjustScale(ships);
        renderer.clearSpace();
        if (log.gameLog && log.gameLog.planet && log.gameLog.planet.safeRadius >= 0)
            renderer.drawSafePlace(log.gameLog.planet.safeRadius);
        if (log.gameLog && log.gameLog.planet && log.gameLog.planet.radius >= 0)
            renderer.drawPlanet(log.gameLog.planet.radius);
        renderer.drawTrajectories(ships, showTrajectories);
        renderer.drawShipsAndCommands(prevTick?.ships, ships, nextTick?.ships, isAutoPlay, getShootRadius);
        if (index < factualTicksLength) {
            let consoleShips = `${tick.tick}`;
            for (const ship of ships) {
                consoleShips += `<p style="color: ${shipColor(ship.ship)}">${JSON.stringify(ship)}</p>`;
            }
            if (consoleElement) {
                consoleElement.innerHTML = consoleShips;
            }
        }
    }
}

export function simulate(ship: IShip, ticks: number, hasPlanet: boolean = true) {
    const points = [];
    const reversed = Math.sign(ticks);
    const s = {
        position: ship.position,
        velocity: {
            x: ship.velocity.x * reversed,
            y: ship.velocity.y * reversed
        },
    };
    for (let i = 0; i < Math.abs(ticks); i++) {
        const dx = Math.abs(s.position.x);
        const dy = Math.abs(s.position.y);
        const maxD = Math.max(dx, dy);
        const newV = { ...s.velocity };
        if (hasPlanet) {
            if (dx === maxD)
                newV.x -= Math.sign(s.position.x) * reversed;
            if (dy === maxD)
                newV.y -= Math.sign(s.position.y) * reversed;
        }
        s.velocity = newV;
        s.position = { x: s.position.x + s.velocity.x, y: s.position.y + s.velocity.y };
        points.push(s.position);
    }
    return points;
}

function getShootRadius(power: number, powerDecrease: number) {
    let r = 0;
    let p = power;
    while (p !== 0) {
        r++;
        p = Math.floor(p / powerDecrease);
    }
    return r - 1;
}

function shipColor(ship: IShip) {
    return ship.role === ApiPlayerRole.Defender ? 'teal' : 'orange';
}