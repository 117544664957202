import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {events} from "../consts/api";
import {uuid} from "uuidv4";
import createQueryString from './createQueryString';

export interface IListener {
    close: () => void;
    subscribe: (eventType: EventType) => void;
    unsubscribe: (eventType: EventType) => void;
}

export enum EventType {
    Submission = 'Submission',
    Scoreboard = 'Scoreboard',
    LightningScoreboard = 'LightningScoreboard',
    NonRatingGame = 'NonRatingGame',
    Team = 'Team',
}

export enum SubmissionChangeType {
    Added = 'Added',
    BuildStatusChanged = 'BuildStatusChanged',
    Activated = 'Activated',
    TestStatusChanged = 'TestStatusChanged',
}

export enum NonRatingGameChangeType {
    Added = 'Added',
    Finished = 'Finished',
}

export enum TeamChangeType {
    Updated = 'Updated',
    Activated = 'Activated',
    Deactivated = 'Deactivated'
}

export interface ISubmissionChangedEventData {
    submissionId: number;
    changeType: SubmissionChangeType;
}

export interface INonRatingGameChangedEventData {
    gameId: string;
    changeType: NonRatingGameChangeType;
}

export interface ITeamChangedEventData {
    changeType: TeamChangeType;
}

export interface IScoreboardChangedEventData {
    tournamentId: number;
}

export interface ILightningScoreboardChangedEventData {
    problemId: string;
    teamId: string;
}

export default function listenEvents(apiKey: string, handler: (eventType: EventType, payload: any) => void): IListener {

    let subscribedEventTypes: { [key: string]: boolean } = {};
    let closed = false;
    let connected = false;
    const requestUrl = `${events}?${createQueryString({sticky: uuid(), apiKey: apiKey})}`

    const connection = new HubConnectionBuilder()
        .withUrl(requestUrl)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

    connection.onclose(async () => {
        console.log("closed");
        connected = false;
        if (!closed)
            setTimeout(() => start(), 10000);
    });

    connection.on("onEvent", (eventType, payload) => {
        if (closed)
            return;
        console.log(eventType, payload);
        handler(eventType, payload);
    });

    async function start() {
        if (closed)
            return;

        try {
            await connection.start();
            connected = true;
            console.log("connected");
        } catch (err) {
            console.log("failed to start", err);
            setTimeout(() => start(), 10000);
            return;
        }

        await updateSubscription();
    }

    async function stop() {
        try {
            await connection.stop();
        } catch (err) {
            console.log("failed to stop", err);
        }
    }

    async function updateSubscription() {
        try {
            let eventTypes = Object.entries(subscribedEventTypes).filter(x => x[1]).map(x => x[0]);
            if (eventTypes.length)
                await connection.send("subscribe", eventTypes.join(","));
            else
                await connection.send("subscribe", 0);
            console.log("subscribed", eventTypes);
        } catch (err) {
            console.log("failed to subscribe", err);
            await stop();
        }
    }

    start();

    return {
        close: function () {
            closed = true;
            stop();
        },
        subscribe: function (eventType: EventType) {
            subscribedEventTypes[eventType] = true;
            if (connected)
                updateSubscription();
        },
        unsubscribe: async function (eventType: EventType) {
            subscribedEventTypes[eventType] = false;
            if (connected)
                updateSubscription();
        }
    };
}
