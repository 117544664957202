import {
    ILogData,
    IGameLogs,
    ApiGameLog,
    IPlanet,
    IPlayer,
    ITick,
    ICommand,
    IShip,
    ApiCommandType,
    IShipAndCommands
} from "./GameLogModels";

export default function convertGameLog(data: ILogData): IGameLogs {
    return {
        isSucceeded: data[0],
        gameType: data[1],
        gameStatus: data[2],
        ticks: data[3],
        players: data[4].map(convertPlayer),
        gameLog: data[5] && data[5][0] !== 0
            ? convertLogItem(data[5])
            : undefined,
    };
}

function convertPlayer(data: number[]): IPlayer {
    return {
        role: data[0],
        score: data[1],
        status: data[2],
    }
}

function convertLogItem(data: any[]): ApiGameLog {
    return {
        planet: data[0] && data[0][1] ? convertPlanet(data[0]) : undefined,
        ticks: data[1].map(convertTick),
    }
}

function convertPlanet(data: number[]): IPlanet {
    return {
        radius: data[0],
        safeRadius: data[1],
    }
}

function convertTick(data: any[]): ITick {
    return {
        tick: data[0],
        ships: data[1].map(createShipAndCommands)
    };
}

function createShipAndCommands(data: any[]): IShipAndCommands {
    return {
        ship: convertShip(data[0]),
        appliedCommands: data[1].map((i: any[]) => convertCommands(i)),
    }
}

function convertShip(data: any[]): IShip {
    return {
        role: data[0],
        shipId: data[1],
        position: { x: data[2][0], y: data[2][1] },
        velocity: { x: data[3][0], y: data[3][1] },
        matter: {
            fuel: data[4][0],
            lasers: data[4][1],
            radiators: data[4][2],
            engines: data[4][3],
        },
        temperature: data[5],
        criticalTemperature: data[6],
        maxFuelBurnSpeed: data[7],
    };
}

function convertCommands(data: any): ICommand | null {
    const type: ApiCommandType = data[0];
    let res: ICommand;
    switch (type) {
        case ApiCommandType.SplitShip:
            return { type, matter: {
                    fuel: data[1][0],
                    lasers: data[1][1],
                    radiators: data[1][2],
                    engines: data[1][3],
                }};
        case ApiCommandType.BurnFuel:
            return {
                type,
                velocity: {x: data[1][0], y: data[1][1]}
            }
        case ApiCommandType.Detonate:
            return {
                type,
                power: data[1],
                powerDecreaseStep: data[2],
            }
        case ApiCommandType.Shoot:
            return {
                type,
                target: {x: data[1][0], y: data[1][1]},
                power: data[2],
                damage: data[3],
                damageDecreaseFactor: data[4],
            }
        default:
            return null;
    }
}