const serverUrl = 'https://icfpc2020-api.testkontur.ru';
const teams = `${serverUrl}/teams`;
export const registration = `${ teams }/register`;
export const currentTeam = `${ teams }/current`;

export const submissions = `${serverUrl}/submissions`;
export const submissionActivate = (id: string) =>  `${submissions}/${id}/activate`;
export const otherTeamsSubmission = `${submissions}/other-teams`;

export const events = `${serverUrl}/events/`; 

export const scoreboard = `${serverUrl}/scoreboard`;
export const problemsScoreboard = `${scoreboard}/lightning`;
export const finalScoreboard = `${scoreboard}/finals`;

export const tournaments = `${serverUrl}/tournaments`;
export const currentTournament = `${tournaments}/current`;

export const games = `${serverUrl}/games`;
export const nonRatingGames = `${games}/non-rating`;
export const nonRatingGamesRun = `${nonRatingGames}/run`;

export const logs = `${serverUrl}/logs`;
