import React from 'react';
import styles from './styles.module.css';
import ScoreboardPage, { SortDirection } from './ScoreboardPage';
import Team from '../../models/Team';
import { ILightningScoreboard, ILightningScoreboardTeam, IProblemDescription } from '../../models/Scoreboard';
import DateTime from '../DateTime';
import { GENERAL } from '../../consts/strings';

interface LightningScoreboardProps {
    team: Team | null;
    scoreboard: ILightningScoreboard;
}


interface LightningScoreboardState {
    sortBy: string;
    sortDirection: SortDirection;
}

export default class LightningScoreboard extends React.Component<LightningScoreboardProps, LightningScoreboardState> {
    state = {
        sortBy: GENERAL,
        sortDirection: SortDirection.decrease,
    }

    render() {
        const { sortBy, sortDirection } = this.state;
        const { scoreboard, team } = this.props;
        
        const teamsOrder = [...scoreboard.teams].sort(sortElements(sortBy, sortDirection));
        const isFrozen = !!scoreboard.frozenAt && new Date(scoreboard.frozenAt) <= new Date(); 
        
        return (
            <>
                <h2>Scoreboard{ team && ': ' + team.teamName } {isFrozen &&
                <span className={styles.frozenTitle}> (frozen at <DateTime dateStr={scoreboard.frozenAt as string}/>)</span>}</h2>
                <table className={ styles.table }>
                    { this.renderHead(teamsOrder) }
                    <tbody>
                    { teamsOrder && teamsOrder.map(this.renderRow) }
                    </tbody>
                </table>
            </>
        )
    }

    renderHead(teamsOrder: ILightningScoreboardTeam[]) {
        const { scoreboard, team } = this.props;
        const { sortBy, sortDirection } = this.state;
        const hasProblems = scoreboard.problems.length > 0;
        const rowSpan = hasProblems ? 2 : 1;

        const buttonClass = `${ styles.sortButton } ${ sortBy === GENERAL
            ? sortDirection === SortDirection.decrease ? styles.sortDown : styles.sortUp
            : '' }`

        return (
            <thead>

            <tr>
                <th style={ { width: 40 } } rowSpan={ rowSpan }>#</th>
                <th rowSpan={ rowSpan } className={ styles.alignLeft }>Team Name</th>
                { hasProblems &&
                <th colSpan={ scoreboard.problems.length }>Problems</th>
                }
                <th style={ { width: 70 } }
                    className={ styles.score }
                    rowSpan={ rowSpan }>
                    <button className={ buttonClass }
                            onClick={ this.changeSorted(GENERAL) }>
                        Score
                    </button>
                </th>
                <th rowSpan={ rowSpan } className={ styles.alignRight }>Solved at</th>
            </tr>
            { hasProblems &&
            <tr>
                { scoreboard.problems.map(this.cellForProblem) }
            </tr>
            }
            { teamsOrder && teamsOrder
                .map((team, i) => ({ team, i }))
                .filter(tuple => tuple.team.team.teamId === team?.teamId && tuple.i > 5)
                .map(tuple => this.renderRow(tuple.team, tuple.i))
            }
            </thead>
        )
    }

    cellForProblem = (problem: IProblemDescription) => {
        const { sortBy, sortDirection } = this.state;
        let text = problem.problemId;
        let description = problem.description;
        if (text === 'request') {
            text = 'R';
        }
        if (text === 'submission') {
            text = 'T';
            description = 'Made submission passed the test'
        }
        if (text.startsWith('tutorial')) {
            text = text.replace('tutorial:', '');
        }
        const buttonClass = `${ styles.sortButton } ${ sortBy === problem.problemId
            ? sortDirection === SortDirection.decrease ? styles.sortDown : styles.sortUp
            : '' }`

        return (
            <th key={ problem.problemId }
                className={ styles.alignRight }
                title={ description }>
                <button onClick={ this.changeSorted(problem.problemId) }
                        className={ buttonClass }>
                    { text }
                </button>
            </th>
        )
    }

    renderRow = (scoreboardTeam: ILightningScoreboardTeam, index: number) => {
        const { team, scoreboard } = this.props;

        const teamInfo = scoreboardTeam.team;
        return (
            <tr key={ index } className={ team?.teamId === teamInfo.teamId ? styles.activeTeam : '' }>
                <td className={ styles.alignCenter }>{ index + 1 }</td>
                { ScoreboardPage.renderTeamName(teamInfo) }
                { scoreboard.problems.map(p => this.renderScoreCell(p, scoreboardTeam)) }
                <td className={ styles.score }>{ scoreboardTeam.score }</td>
                <td className={ styles.alignRight }><DateTime dateStr={ scoreboardTeam.solvedAt }/>
                </td>
            </tr>
        )
    }

    renderScoreCell(p: IProblemDescription, scoreboardTeam: ILightningScoreboardTeam) {
        return (
            <td key={ p.problemId }
                className={ styles.alignRight }
                title={ p.problemId in scoreboardTeam.problems
                    ? `Solved at ${ ScoreboardPage.createDate(scoreboardTeam.problems[p.problemId].solvedAt) }`
                    : undefined }>
                { p.problemId in scoreboardTeam.problems
                    ? scoreboardTeam.problems[p.problemId].score
                    : <span className={ styles.lighten }>—</span> }
            </td>
        )
    }

    changeSorted = (sortBy: string) => () => {
        if (sortBy === this.state.sortBy) {
            const sortDirection = this.state.sortDirection === SortDirection.decrease
                ? SortDirection.increase
                : SortDirection.decrease
            this.setState({ sortDirection })
            return;
        }
        this.setState({ sortBy, sortDirection: SortDirection.decrease })
    }

}

function sortElements(sortBy: string, sortDirection: SortDirection) {
    return (a: ILightningScoreboardTeam, b: ILightningScoreboardTeam) => {
        let aScore, bScore;
        if (sortBy === GENERAL) {
            aScore = { score: a.score, solvedAt: a.solvedAt };
            bScore = { score: b.score, solvedAt: b.solvedAt };
        } else {
            const aProblem = a.problems[sortBy];
            const bProblem = b.problems[sortBy];
            aScore = aProblem || { score: 0, solvedAt: new Date(0) }
            bScore = bProblem || { score: 0, solvedAt: new Date(0) }
        }

        if (sortDirection === SortDirection.decrease) {
            if (aScore.score > bScore.score) return -1;
            if (aScore.score < bScore.score) return 1;
            if (aScore.solvedAt < bScore.solvedAt) return -1;
            if (aScore.solvedAt > bScore.solvedAt) return 1;
            return 0;
        }

        if (aScore.score < bScore.score) return -1;
        if (aScore.score > bScore.score) return 1;
        if (aScore.solvedAt > bScore.solvedAt) return -1;
        if (aScore.solvedAt < bScore.solvedAt) return 1;
        return 0;
    }
}

