import React from 'react';
import styles from './styles.module.css';
import Team from '../../models/Team';
import RegistrationTeam from '../../models/RegistrationTeam';
import TeamForm from '../TeamForm';
import ShowAndCopyForm from "../ShowAndCopyForm";

type TeamInfoPageProps = {
    team: Team | null;
    loading: boolean;
    editTeam: (team: RegistrationTeam) => void;
}

export default function TeamInfoPage(props: TeamInfoPageProps) {
    const { team } = props;

    if (!team) return null;

    const teamForForm: RegistrationTeam = {
        teamName: team.teamName,
        gitRepositoryUrl: team.gitRepositoryUrl,
        customData_peopleAmount: team.customData.peopleAmount || '',
        customData_country: team.customData.country || '',
        customData_email: team.customData.email || '',
    }

    return (
        <main className={ styles.root }>
            <h2>Profile: { team.teamName }</h2>

            <h3>API key</h3>
            <p>Beware not to lose your API key. Use it:</p>
            <ul>
                <li>to log in to the team profile (this page)</li>
                <li>to send requests to Organizers' server</li>
            </ul>
            <br/>
            <ShowAndCopyForm name="API key"
                             value={ team.apiKey }/>

            <br/><br/>

            <h3>Deploy key</h3>
            <p>Add it to your repository as per <a href="https://github.com/icfpcontest2020/dockerfiles#getting-started"
                                                   target="_blank" rel="noopener noreferrer">submission rules</a>.</p>
            <ShowAndCopyForm name="Deploy key"
                             value={ team.deployKey }/>

            <br/><br/>

            <h3>Team Info</h3>
            <div>
                <TeamForm startValue={ teamForForm }
                          loading={ props.loading }
                          onSubmit={ props.editTeam } isEditForm/>
            </div>
        </main>
    )
}
