import React from 'react';
import styles from './styles.module.css';
import Team from '../../models/Team';
import Submission, { SubmissionStatus } from '../../models/Submission';
import DateTime from '../DateTime';
import Toast from '../Toast';
import PlayIcon from '../PlayIcon';

interface SubmissionsPageProps {
    team: Team | null;
    submissions: Submission[] | null;
    loadSubmissions: () => void;
    updateLastSeenSubmission: (id: string) => void;
    activateSubmission: (id: string) => void;
}

interface SubmissionsPageState {
    showChangeSubmission: boolean;
}

export default class SubmissionsPage extends React.Component<SubmissionsPageProps, SubmissionsPageState> {
    state = {
        showChangeSubmission: false,
    }

    componentDidMount() {
        if (!this.props.submissions) {
            this.getSubmissions();
        }
    }

    componentDidUpdate(prevProps: Readonly<SubmissionsPageProps>) {
        this.checkLastSeenSubmission(prevProps);
        this.checkActiveSubmissionChange(prevProps);
    }

    render() {
        const { team } = this.props;
        const { showChangeSubmission } = this.state;

        if (!team) return null

        return (
            <div>
                <h2>Submissions: { team.teamName }</h2>

                <p className={ styles.help }>A successful submission should <code
                    className={ styles.branch }>join</code> and <code
                    className={ styles.branch }>start</code> a game on <a
                    href='https://message-from-space.readthedocs.io/en/latest/game.html#join'
                    target='_blank' rel="noopener noreferrer">the alien server</a>.<br/>
                    Note that any starter kit is no longer a successful submission.</p>

                { this.renderSubmissions() }

                { this.renderHelpMessage() }
                { showChangeSubmission && <Toast message={ 'Active submission changed' }
                                                 onClose={ this.closeToast }/> }
            </div>
        )
    }

    renderSubmissions() {
        const { submissions } = this.props;
        return (
            <table className={ styles.table }>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Commit Message</th>
                    <th>Created At</th>
                    <th>Status</th>
                    <th>Build</th>
                    <th>Test</th>
                    <th>In Rating Games</th>
                </tr>
                </thead>
                <tbody>
                { submissions && submissions.length === 0 && (
                    <tr>
                        <td colSpan={ 7 } style={ { textAlign: "center" } }>
                            <p>No submissions yet.</p>
                        </td>
                    </tr>
                ) }
                { submissions && submissions.map(s => this.renderRow(s)) }
                </tbody>
            </table>
        )
    }

    renderRow(s: Submission) {
        const { team } = this.props;
        const statusTexts: { [key: string]: string } = {
            [SubmissionStatus.Failed_Build]: 'Build failed',
            [SubmissionStatus.Failed_Test]: 'Test failed',
            [SubmissionStatus.Failed_Unknown]: 'Failed',
            [SubmissionStatus.Succeeded]: 'Test passed',
        }

        const status = statusTexts[s.status] || s.status
        const isFailed = status.toLowerCase().indexOf('failed') !== -1

        return (
            <tr key={ s.commitHash + s.createdAt }
                className={ s.status !== SubmissionStatus.Succeeded ? '' : styles.succeeded }
                onClick={ this.activateSubmission(s) }>
                <td>{ s.commitHash?.substring(0, 6) }</td>
                <td><span className={ styles.pre }>
                    { s.branchName && <span className={ styles.branch }>[{ s.branchName }] </span> }
                    { s.commitMessage }</span></td>
                <td><DateTime dateStr={ s.createdAt }/></td>
                <td className={ isFailed ? styles.statusFailed : '' }>{ status }</td>
                <td>
                    { s.buildLogKey && team &&
                    <a target='_blank'
                       rel="noopener nofollow noreferrer"
                       className={ styles.logLink }
                       onClick={ this.stopEvent }
                       href={ `https://icfpc2020-api.testkontur.ru/logs/?logKey=${ encodeURIComponent(s.buildLogKey) }&apiKey=${ team.apiKey }` }>
                        Log
                    </a> }
                </td>
                <td>
                    { s.attackerTestLogKey && team &&
                    <div><a target='_blank'
                       rel="noopener nofollow noreferrer"
                       className={ styles.logLink }
                       onClick={ this.stopEvent }
                       href={ `https://icfpc2020-api.testkontur.ru/logs/?logKey=${ encodeURIComponent(s.attackerTestLogKey) }&apiKey=${ team.apiKey }` }>
                        Attacker Log
                    </a></div> }
                    { s.defenderTestLogKey && team &&
                    <div><a target='_blank'
                       rel="noopener nofollow noreferrer"
                       className={ styles.logLink }
                       onClick={ this.stopEvent }
                       href={ `https://icfpc2020-api.testkontur.ru/logs/?logKey=${ encodeURIComponent(s.defenderTestLogKey) }&apiKey=${ team.apiKey }` }>
                       Defender Log
                    </a></div> }
                </td>
                <td>{ s.active ? <PlayIcon/> : <button className={ styles.icon }><PlayIcon/> play</button> }</td>
            </tr>
        )
    }

    renderHelpMessage() {
        return (
            <div className={ styles.help }>
                <p>Please note that the <code className={ styles.branch }>[submission]</code> branch is required.</p>
                <p>If you need assistance,
                    please read the <a href="https://github.com/icfpcontest2020/dockerfiles/blob/master/README.md"
                                       target="_blank" rel="noopener noreferrer">submission rules</a>,
                    then file an <a
                        href="https://github.com/icfpcontest2020/dockerfiles/issues/new?labels=submissions&template=submissions.md&title=Submissions%3A+"
                        target="_blank" rel="noopener noreferrer">issue</a>.
                </p>
            </div>
        )
    }

    getSubmissions = () => {
        this.props.loadSubmissions();
    }

    stopEvent = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    closeToast = () => {
        this.setState({ showChangeSubmission: false })
    }

    activateSubmission = (s: Submission) => () => {
        if (s.active || s.status !== SubmissionStatus.Succeeded) return;

        this.props.activateSubmission(s.submissionId);
    }

    checkLastSeenSubmission(prevProps: Readonly<SubmissionsPageProps>) {
        const hasSubmissions = this.props.submissions && this.props.submissions.length > 0;

        if (!hasSubmissions) return;
        const submissions = this.props.submissions as Array<Submission>;

        const loadedSubmissions = !prevProps.submissions && Boolean(this.props.submissions);
        const changedSubmissions = prevProps.submissions &&
            prevProps.submissions[0]?.submissionId !== submissions[0]?.submissionId;

        if (loadedSubmissions || changedSubmissions) {
            const id = submissions[0]?.submissionId;

            this.props.updateLastSeenSubmission(id);
        }
    }

    checkActiveSubmissionChange(prevProps: Readonly<SubmissionsPageProps>) {
        if (!this.props.submissions || !prevProps.submissions) {
            return;
        }
        const currentActive = this.props.submissions.find(s => s.active);
        const prevActive = prevProps.submissions.find(s => s.active);

        if (currentActive && currentActive.submissionId !== prevActive?.submissionId) {
            this.setState({ showChangeSubmission: true });
        }
    }
}
