import IScoreboardStore from '../models/store/scoreboard';
import IScoreboardAction from '../models/actions/scoreboard';
import { LOAD_FINAL_SCOREBOARD, LOAD_PROBLEM_SCOREBOARD, LOAD_TOTAL_SCOREBOARD } from '../consts/actions';

const defaultStore: IScoreboardStore ={
    lightningScoreboard: null,
    totalScoreboard: null,
    finalScoreboard: null,
}

function scoreboardReducer(store = defaultStore, action: IScoreboardAction) {
    switch (action.type) {
        case LOAD_PROBLEM_SCOREBOARD:
            return {
                ...store,
                lightningScoreboard: action.payload,
            }
        case LOAD_TOTAL_SCOREBOARD:
            return {
                ...store,
                totalScoreboard: action.payload,
            }
        case LOAD_FINAL_SCOREBOARD:
            return {
                ...store,
                finalScoreboard: action.payload,
            }
        default:
            return store;
    }
}

export default scoreboardReducer;
